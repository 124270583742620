import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-retrobg text-retrocream p-2 md:p-3 font-body z-10">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-2 text-sm md:text-base">
        
        {/* Company Information */}
        <div className="text-center md:text-left">
          <p className="mt-2">
            PixeLore is a community-crafted Wiki made for Players by the Players.
          </p>
        </div>

        {/* Quick Links */}
        <div className="text-center md:text-right">
          <div className="mt-1 space-x-2 md:space-x-4">
            <a href="/about" className="hover:underline">About Me</a>
            <a href="https://www.reddit.com/r/PixelsGame/" target="_blank" rel="noreferrer" className="hover:underline">Pixels Reddit</a>
            <a href="https://discord.gg/pixels" target="_blank" rel="noreferrer" className="hover:underline">Pixels Discord</a>
            <a href="https://twitter.com/messages/compose?recipient_id=1824947304706588672" className="hover:underline">Contact Me</a>
            <a href="/others" className="hover:underline">Other Resources</a>
          </div>
        </div>
      </div>

      {/* Legal Information */}
      <div className="mt-1 md:mt-1 text-center border-t border-retrosilver pt-2 text-xs md:text-sm">
        <p>&copy; 2024 PixeLore. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
