import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { currencyMapping, itemsToExclude, industryMap } from "../utils/mappings";
import ItemModal from "../components/itemModal";
import BackToTopGlobal from "../components/BackToTop2";
import { fetchItems } from "../utils/fetchItemData";

const Skills = () => {
  const [selectedCategory, setSelectedCategory] = useState("Breakdown");
  const navigate = useNavigate();
  const [selectedSkill, setSelectedSkill] = useState("farming");
  const [levels, setLevels] = useState([]);
  const [items, setItems] = useState([]);
  const [skillTables, setSkillTables] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchItems(setItems);
  }, []);


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update the search query
  };

  const openModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const filteredData = skillTables[selectedSkill]?.data.filter((row) => {
    if (!searchQuery) {
      return true;
    }
  
    const lowerCaseQuery = searchQuery.toLowerCase();
  
    return Object.values(row).some((value) => {
      if (React.isValidElement(value)) {
        const textContent = React.Children.toArray(value.props.children)
          .map(child => (typeof child === 'string' ? child : ''))
          .join('');
        return textContent.toLowerCase().includes(lowerCaseQuery);
      } else if (typeof value === 'string' || typeof value === 'number') {
        return value.toString().toLowerCase().includes(lowerCaseQuery);
      }
      return false;
    });
  });

  useEffect(() => {
    const itemNameLookup = items.reduce((lookup, item) => {
      lookup[item.id] = item.name;
      return lookup;
    }, {});

    const tables =
      items && Array.isArray(items)
        ? {
            farming: {
              headings: ["Item Name", "Level", "Tier", "Energy", "Harvest Energy", "Seed Price", "Grow Time", "Result", "Exp", "Exp/Energy", "Recipe", "Industry"],
              data: [
                ...items
                  .filter((item) => item.type && item.type === "Farming" && item.plantable && item.plantable.growthTime && item.name && !itemsToExclude.includes(item.id))
                  .map((item) => {
                    return {
                      itemName: (
                        <>
                          <span key={item.id} onClick={() => openModal(item)} className="cursor-zoom-in">
                            <img src={item.image} alt={item.name} style={{ width: "20px", marginRight: "10px" }} />
                            {item.name}
                          </span>
                        </>
                      ),
                      level: item.plantable?.level || 0,
                      tier: item.tier || 0,
                      plantEnergy: item.plantable?.plantEnergy * -1 || 0,
                      harvestEnergy: item.plantable?.harvestEnergy || 0,
                      seedprice:
                        item.purchaseable && item.purchaseable[0]?.buyPrice ? item.purchaseable[0]?.buyPrice + " " + currencyMapping[item.purchaseable[0]?.currency] : "N/A",
                      growTime: item.plantable?.growthTime ? item.plantable.growthTime + " min(s)" : "N/A",
                      result: item.plantable?.result ? (
                        <>
                          <img src={item.plantable.result.image} alt={item.plantable.result.name} style={{ width: "20px", marginRight: "10px" }} />
                          {item.plantable.result.name}
                        </>
                      ) : (
                        "N/A"
                      ),
                      exp: item.plantable?.exp || 0,
                      expperE: ((item.plantable?.exp || 0) / ((item.plantable?.plantEnergy * -1 || 0) + (item.plantable?.harvestEnergy || 0))).toFixed(2),
                      recipe: "None",
                      industry: "Planting/Soil",
                    };
                  }),
                ...items
                  .filter((item) => item.craftable !== "Not Craftable")
                  .flatMap((item) =>
                    item.recipes
                      .filter((recipe) => recipe.expType === "farming" && !itemsToExclude.includes(recipe.id))
                      .map((recipe) => ({
                        recipeName: (
                          <span key={recipe.id} onClick={() => openModal(item)} className="cursor-zoom-in">
                            <img src={item.image} alt={item.name} style={{ width: "20px", marginRight: "10px" }} />
                            {recipe.name}
                          </span>
                        ),
                        level: recipe.level || 0,
                        tier: item.tier,
                        energy: recipe.energyCost || 0,
                        harvestEnergy: "N/A",
                        seedPrice: "N/A",
                        craftTime: recipe.time + " min(s)",
                        result: recipe.name,
                        exp: recipe.exp || 0,
                        expperE: recipe.energyCost ? (recipe.exp / recipe.energyCost).toFixed(2) : "N/A",
                        requiredItems: recipe.requiredItems.map((reqItem) => `${reqItem.quantity} x ${reqItem.name}`).join(", "),
                        industry: industryMap[recipe.expType] || "Unknown",
                      }))
                  ),
              ].sort((a, b) => a.level - b.level), // Sort by level
            },
            cooking: {
              headings: ["Recipe Name", "Level", "Tier", "Recipe", "Energy", "Craft Time", "Exp", "Exp/Energy", "Industry"],
              data: items
                .filter((item) => item.craftable !== "Not Craftable")
                .flatMap((item) =>
                  item.recipes
                    .filter((recipe) => recipe.expType === "cooking" && !itemsToExclude.includes(recipe.id))
                    .map((recipe) => ({
                      recipeName: (
                        <span key={recipe.id} onClick={() => openModal(item)} className="cursor-zoom-in">
                          <img src={item.image} alt={item.name} style={{ width: "20px", marginRight: "10px" }} />
                          {recipe.name}
                        </span>
                      ),
                      level: recipe.level || 0,
                      tier: item.tier,
                      requiredItems: recipe.requiredItems.map((reqItem) => `${reqItem.quantity} x ${reqItem.name}`).join(", "),
                      energy: recipe.energyCost || 0,
                      craftTime: recipe.time + " min(s)",
                      exp: recipe.exp || 0,
                      expperE: recipe.energyCost ? (recipe.exp / recipe.energyCost).toFixed(2) : "N/A",
                      industry: industryMap[recipe.expType] || "Unknown",
                    }))
                )
                .sort((a, b) => a.level - b.level),
            },
            forestry: {
              headings: ["Drops", "Tier", "Drop Chance", "Exp", "Land Type"],
              data: [
                { possibleDrops: "1 x Whittlewood", tier: 1, dropChance: "TBD", exp: 5, landType: "More information coming" },
                { possibleDrops: "2 x Whittlewood", tier: 1, dropChance: "TBD", exp: 9, landType: "More information coming" },
                { possibleDrops: "3 x Whittlewood", tier: 1, dropChance: "TBD", exp: 14, landType: "More information coming" },
                { possibleDrops: "4 x Whittlewood", tier: 1, dropChance: "TBD", exp: 18, landType: "More information coming" },
                { possibleDrops: "5 x Whittlewood", tier: 1, dropChance: "TBD", exp: 23, landType: "More information coming" },
                { possibleDrops: "Sap", tier: 1, dropChance: "TBD", exp: 30, landType: "More information coming" },
                { possibleDrops: "Craftbark Log ( 1 - 8 Possible)", tier: 2, dropChance: "TBD", exp: "22 Exp per Log", landType: "More information coming" },
                { possibleDrops: "Sap", tier: 1, dropChance: "TBD", exp: 30, landType: "More information coming" },
                { possibleDrops: "Sawstump Log ( 1 - 8 Possible)", tier: 3, dropChance: "TBD", exp: "62 Exp Per Log", landType: "More information coming" },
                { possibleDrops: "Sap", tier: 3, dropChance: "TBD", exp: 30, landType: "More information coming" },
                { possibleDrops: "Borebranch Log ( 1 - 8 Possible)", tier: 4, dropChance: "TBD", exp: "136 Exp Per Log", landType: "More information coming" },
                { possibleDrops: "Sap", tier: 4, dropChance: "TBD", exp: 30, landType: "More information coming" },
              ],
            },
            woodwork: {
              headings: ["Recipe Name", "Level", "Tier", "Recipe", "Energy", "Craft Time", "Exp", "Exp/Energy", "Industry"],
              data: items
                .filter((item) => item.craftable !== "Not Craftable")
                .flatMap((item) =>
                  item.recipes
                    .filter((recipe) => recipe.expType === "woodwork" && !itemsToExclude.includes(recipe.id))
                    .map((recipe) => ({
                      recipeName: (
                        <>
                          <span key={item.id} onClick={() => openModal(item)} className="cursor-zoom-in">
                            <img src={item.image} alt={item.name} style={{ width: "20px", marginRight: "10px" }} />
                            {recipe.name}
                          </span>
                        </>
                      ),
                      level: recipe.level || 0,
                      tier: item.tier,
                      requiredItems: recipe.requiredItems
                        .map((reqItem) => {
                          return `${reqItem.quantity} x ${reqItem.name}`;
                        })
                        .join(", "),
                      energy: recipe.energyCost || 0,
                      craftTime: recipe.time + " min(s)",
                      exp: recipe.exp || 0,
                      expperE: ((recipe.exp || 0) / (recipe.energyCost || 0)).toFixed(2),
                      industry: industryMap[recipe.expType],
                    }))
                )
                .sort((a, b) => a.level - b.level),
            },
            mining: {
              headings: ["Drops", "Tier", "Drop Chance", "Exp"],
              data: [
                { possibleDrops: "Copperite Ore (2 or 6)", tier: 1, dropChance: "TBD", exp: 40 },
                { possibleDrops: "Clay Matrix (2 or 6)", tier: 1, dropChance: "TBD", exp: 40 },
                { possibleDrops: "Ochrux Matrix (2 or 6)", tier: 1, dropChance: "TBD", exp: 40 },
                { possibleDrops: "Gravelglass Matrix (2 or 6)", tier: 1, dropChance: "TBD", exp: 40 },
                { possibleDrops: "Copperite Ore (2, 4, 6, 8, 10, 12, 16, 20, 24)", tier: 2, dropChance: "TBD", exp: 100 },
                { possibleDrops: "Marbleite Matrix (2, 4, 6, 8, 10, 12, 16, 20, 24)", tier: 2, dropChance: "TBD", exp: 100 },
                { possibleDrops: "Ochrux Matrix (2, 4, 6, 8, 10, 12, 16, 20, 24)", tier: 2, dropChance: "TBD", exp: 100 },
                { possibleDrops: "Bronzenite Ore (2, 4, 6, 8, 10, 12, 16, 20, 24)", tier: 2, dropChance: "TBD", exp: 100 },
                { possibleDrops: "Clay Matrix (2, 4, 6, 8, 10, 12, 16, 20, 24)", tier: 2, dropChance: "TBD", exp: 100 },
                { possibleDrops: "Copperite Matrix (2, 4, 6, 8, 10, 12, 16, 20, 24)", tier: 2, dropChance: "TBD", exp: 100 },
                { possibleDrops: "Marbleite Matrix (1-10, 12, 20, 30)", tier: 3, dropChance: "TBD", exp: 200 },
                { possibleDrops: "Irontite Ore (1-10, 12, 20, 30)", tier: 3, dropChance: "TBD", exp: 200 },
                { possibleDrops: "Orchrux Matrix (1-10, 12, 20, 30)", tier: 3, dropChance: "TBD", exp: 200 },
                { possibleDrops: "Bedrock Matrix (1-10, 12, 20, 30)", tier: 3, dropChance: "TBD", exp: 200 },
                { possibleDrops: "Gravelglass Matrix (1-10, 12, 20, 30)", tier: 3, dropChance: "TBD", exp: 200 },
                { possibleDrops: "Copperite Ore (1-10, 12, 20, 30)", tier: 3, dropChance: "TBD", exp: 200 },
                { possibleDrops: "Bronzenite Ore (1-10, 12, 20, 30)", tier: 3, dropChance: "TBD", exp: 200 },
                { possibleDrops: "Clay Matrix (1-10, 12, 20, 30)", tier: 3, dropChance: "TBD", exp: 200 },
                { possibleDrops: "Claricite Matrix (1-10, 12, 20, 30)", tier: 4, dropChance: "TBD", exp: 240 },
                { possibleDrops: "Adamaxium Ore (1-10, 12, 20, 30)", tier: 4, dropChance: "TBD", exp: 240 },
                { possibleDrops: "Bedrock Matrix (1-10, 12, 20, 30)", tier: 4, dropChance: "TBD", exp: 240 },
                { possibleDrops: "Copperite Ore (1-10, 12, 20, 30)", tier: 4, dropChance: "TBD", exp: 240 },
                { possibleDrops: "Bronzenite Ore (1-10, 12, 20, 30)", tier: 4, dropChance: "TBD", exp: 240 },
                { possibleDrops: "Gravelglass Matrix (1-10, 12, 20, 30)", tier: 4, dropChance: "TBD", exp: 240 },
                { possibleDrops: "Clay Matrix (1-10, 12, 20, 30)", tier: 4, dropChance: "TBD", exp: 240 },
                { possibleDrops: "Marbleite Matrix (1-10, 12, 20, 30)", tier: 4, dropChance: "TBD", exp: 240 },
                { possibleDrops: "Irontite Ore (1-10, 12, 20, 30)", tier: 4, dropChance: "TBD", exp: 240 },
                { possibleDrops: "Orchrux Matrix (1-10, 12, 20, 30)", tier: 4, dropChance: "TBD", exp: 240 },
              ],
            },
            stoneshaping: {
              headings: ["Recipe Name", "Level", "Tier", "Recipe", "Energy", "Craft Time", "Exp", "Exp/Energy", "Industry"],
              data: items
                .filter((item) => item.craftable !== "Not Craftable")
                .flatMap((item) =>
                  item.recipes
                .filter((recipe) => recipe.expType === "stoneshaping" && !itemsToExclude.includes(recipe.id))
                .map((recipe) => ({
                  recipeName: (
                    <span key={recipe.id} onClick={() => openModal(item)} className="cursor-zoom-in">
                      <img src={item.image} alt={item.name} style={{ width: "20px", marginRight: "10px" }} />
                      {recipe.name}
                    </span>
                  ),
                      level: recipe.level || 0,
                      tier: item.tier,
                      requiredItems: recipe.requiredItems
                        .map((reqItem) => {
                          return `${reqItem.quantity} x ${reqItem.name}`;
                        })
                        .join(", "),
                      energy: recipe.energyCost || 0,
                      craftTime: recipe.time + " min(s)",
                      exp: recipe.exp || 0,
                      expperE: ((recipe.exp || 0) / (recipe.energyCost || 0)).toFixed(2),
                      industry: industryMap[recipe.expType],
                    }))
                )
                .sort((a, b) => a.level - b.level),
            },
            metalworking: {
              headings: ["Recipe Name", "Level", "Tier", "Recipe", "Energy", "Craft Time", "Exp", "Exp/Energy", "Industry"],
              data: items
                .filter((item) => item.craftable !== "Not Craftable")
                .flatMap((item) =>
                  item.recipes
                    .filter((recipe) => recipe.expType === "metalworking" && !itemsToExclude.includes(recipe.id))
                    .map((recipe) => ({
                      recipeName: (
                        <span key={recipe.id} onClick={() => openModal(item)} className="cursor-zoom-in">
                          <img src={item.image} alt={item.name} style={{ width: "20px", marginRight: "10px" }} />
                          {recipe.name}
                        </span>
                      ),
                      level: recipe.level || 0,
                      tier: item.tier,
                      requiredItems: recipe.requiredItems
                        .map((reqItem) => {
                          return `${reqItem.quantity} x ${reqItem.name}`;
                        })
                        .join(", "),
                      energy: recipe.energyCost || 0,
                      craftTime: recipe.time + " min(s)",
                      exp: recipe.exp || 0,
                      expperE: ((recipe.exp || 0) / (recipe.energyCost || 0)).toFixed(2),
                      industry: industryMap[recipe.expType],
                    }))
                )
                .sort((a, b) => a.level - b.level),
            },
            petcare: {
              headings: ["Recipe Name", "Industry", "Level", "Tier", "Recipe", "Energy", "Craft Time", "Exp", "Exp/Energy", "Drop Chance/Amt"],
              data: [
                // Existing filtered data from itemsData
                ...items
                  .filter((item) => item.craftable !== "Not Craftable")
                  .flatMap((item) =>
                    item.recipes
                      .filter((recipe) => recipe.expType === "petcare" && !itemsToExclude.includes(recipe.id))
                      .map((recipe) => ({
                        recipeName: (
                          <span key={recipe.id} onClick={() => openModal(item)} className="cursor-zoom-in">
                            <img src={item.image} alt={item.name} style={{ width: "20px", marginRight: "10px" }} />
                            {recipe.name}
                          </span>
                        ),
                        level: recipe.level || 0,
                        tier: item.tier,
                        requiredItems: recipe.requiredItems
                          .map((reqItem) => {
                            return `${reqItem.quantity} x ${reqItem.name}`;
                          })
                          .join(", "),
                        energy: recipe.energyCost || 0,
                        craftTime: recipe.time + " min(s)",
                        exp: recipe.exp || 0,
                        expperE: ((recipe.exp || 0) / (recipe.energyCost || 0)).toFixed(2),
                        industry: industryMap[recipe.expType],
                        dropChange: "100%",
                      }))
                  ),
                {
                  recipeName: itemNameLookup["itm_egg"],
                  industry: "Coop",
                  level: 0,
                  tier: "N/A",
                  requiredItems: "",
                  energy: 1.5,
                  craftTime: "1h",
                  exp: 2,
                  expperE: (2 / 1.5).toFixed(2),
                  dropchance: "100% / 2",
                },
                {
                  recipeName: itemNameLookup["itm_seltsamEgg"],
                  industry: "Coop",
                  level: 2,
                  tier: "N/A",
                  requiredItems: "",
                  energy: 1.5,
                  craftTime: "1h",
                  exp: 7,
                  expperE: (7 / 1.5).toFixed(2),
                  dropchance: "10% / 1",
                },
                {
                  recipeName: itemNameLookup["itm_eggsplosive"],
                  industry: "Coop",
                  level: 5,
                  tier: "N/A",
                  requiredItems: "",
                  energy: 1.5,
                  craftTime: "1h",
                  exp: 36,
                  expperE: (36 / 1.5).toFixed(2),
                  dropchance: "2% / 1",
                },
                {
                  recipeName: "Silk Fiber",
                  industry: "Slug Hutch",
                  level: 0,
                  tier: "N/A",
                  requiredItems: "",
                  energy: 5,
                  craftTime: "1h",
                  exp: 2,
                  expperE: (2 / 5).toFixed(2),
                  dropchance: "100% / 3",
                },
                {
                  recipeName: "Silk Slug Slime",
                  industry: "Slug Hutch",
                  level: 2,
                  tier: "N/A",
                  requiredItems: "",
                  energy: 5,
                  craftTime: "1h",
                  exp: 14,
                  expperE: (14 / 5).toFixed(2),
                  dropchance: "20% / 2",
                },
                {
                  recipeName: "Silk Slug Spider",
                  industry: "Slug Hutch",
                  level: 5,
                  tier: "N/A",
                  requiredItems: "",
                  energy: 5,
                  craftTime: "1h",
                  exp: 43,
                  expperE: (43 / 5).toFixed(2),
                  dropchance: "5% / 1",
                },
                {
                  recipeName: "Honey",
                  industry: "Apiary",
                  level: 0,
                  tier: "N/A",
                  requiredItems: "",
                  energy: 2.5,
                  craftTime: "45m",
                  exp: 1,
                  expperE: (1 / 2.5).toFixed(2),
                  dropchance: "100% / 3",
                },
                {
                  recipeName: "Wax",
                  industry: "Apiary",
                  level: 2,
                  tier: "N/A",
                  requiredItems: "",
                  energy: 2.5,
                  craftTime: "45m",
                  exp: 5.5,
                  expperE: (5.5 / 2.5).toFixed(2),
                  dropchance: "15% / 2",
                },
                {
                  recipeName: "Queen Bee",
                  industry: "Apiary",
                  level: 5,
                  tier: "N/A",
                  requiredItems: "",
                  energy: 2.5,
                  craftTime: "45m",
                  exp: 23.5,
                  expperE: (23.5 / 2.5).toFixed(2),
                  dropchance: "0.5% / 1",
                },
                {
                  recipeName: "Cow Turd",
                  industry: "Cow",
                  level: 0,
                  tier: "N/A",
                  requiredItems: "",
                  energy: 0,
                  craftTime: "1h20m",
                  exp: 0,
                  expperE: 0,
                  dropchance: "100% / 1",
                },
                {
                  recipeName: "Milk",
                  industry: "Cow",
                  level: 0,
                  tier: "N/A",
                  requiredItems: "",
                  energy: 0,
                  craftTime: "1h20m",
                  exp: 0,
                  expperE: 0,
                  dropchance: "20% / (1-3)",
                },
              ].sort((a, b) => a.level - b.level),
            },
            business: {
              headings: ["Recipe Name", "Level", "Tier", "Recipe", "Energy", "Craft Time", "Exp", "Exp/Energy", "Industry"],
              data: items
                .filter((item) => item.craftable !== "Not Craftable")
                .flatMap((item) =>
                  item.recipes
                    .filter((recipe) => recipe.expType === "business" && !itemsToExclude.includes(recipe.id))
                    .map((recipe) => ({
                      recipeName: (
                        <span key={recipe.id} onClick={() => openModal(item)} className="cursor-zoom-in">
                          <img src={item.image} alt={item.name} style={{ width: "20px", marginRight: "10px" }} />
                          {recipe.name}
                        </span>
                      ),
                      level: recipe.level || 0,
                      tier: item.tier,
                      requiredItems: recipe.requiredItems
                        .map((reqItem) => {
                          return `${reqItem.quantity} x ${reqItem.name}`;
                        })
                        .join(", "),
                      energy: recipe.energyCost || 0,
                      craftTime: recipe.time + " min(s)",
                      exp: recipe.exp || 0,
                      expperE: ((recipe.exp || 0) / (recipe.energyCost || 0)).toFixed(2),
                      industry: industryMap[recipe.expType],
                    }))
                )
                .sort((a, b) => a.level - b.level),
            },
          }
        : {};

    setSkillTables(tables);
  }, [items]);

  useEffect(() => {
    const fetchLevelData = async () => {
      try {
        const response = await fetch("https://api.pixelore.wiki/api/levels");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        // Convert the object to an array
        const levelsArray = Object.values(data);
        setLevels(levelsArray);
      } catch (error) {
        console.error("Failed to fetch level data", error);
      }
    };

    if (selectedCategory === "Levels") {
      fetchLevelData();
    }
  }, [selectedCategory]);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      setSelectedCategory(hash.charAt(0).toUpperCase() + hash.slice(1));
    }
  }, []);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const skills = {
    farming: {
      name: "Farming",
      description:
        "Farming is one of the skills in Pixels that plays a major role in many other industries." +
        " It is a Resource Skill and is one of the core skills, imo - the resources you get from Farming can be used directly in the Task Board, can be used in Cooking, Grilling, making Wines and other drinks." +
        "\n\nHere are some tips about farming that might help you as you start your farming journey:" +
        "\n🧑🏻‍🌾For every crop you plant, make sure you have the adequate tool to harvest. For example if you're planting a Tier 3 Crop, you need a Tier 3 Shears to Harvest the crop." +
        "\n🧑🏻‍🌾The Rusty Watering Can has no tier.\n🧑🏻‍🌾If you dont harvest within 5 hours of the crop being fully grown, your plant will die." +
        "\n🧑🏻‍🌾If someone else harvests your dead crop, you don't get back the seeds unless you reuse the exact same soil." +
        "\n🧑🏻‍🌾Watering always costs 0.5 Energy" +
        "\n🧑🏻‍🌾There's no public farming areas, but there's a public windmill located top left of Terra Villa." +
        "\n🧑🏻‍🌾You can also buy Tier 1 soils for 100 coins from the store, and place them on your Speck for easy beginner farming." +
        "\n🧑🏻‍🌾Soils cannot be placed inside the house, only exterior on lands.",
    },
    cooking: {
      name: "Cooking",
      description:
        "Cooking is a Crafting Skill that leads you down the path of energy-rejuvenation. Some of the items made with cooking can be good for energy or strictly utilized in the task board. There's also a lot of items that you can make to craft other items, like jams or purees." +
        "\n\nHere are some tips about cooking that might help you as you start your cooking journey:" +
        "\n👨🏻‍🍳You can use higher tier stoves even if you haven't reached that tier of cooking. This is super helpful because Tier 3 and Tier 4 stoves don't require wood." +
        "\n👨🏻‍🍳If you're using a Tier 1 or Tier 2 stove, you need to place 1 wood first before you can use the stove" +
        "\n👨🏻‍🍳Wood lasts for 1 hour before it dies out and then you need to apply more wood." +
        "\n👨🏻‍🍳The grills are another place where you can raise your cooking skill - there are 3 grills in the game and their recipes are each different [Dry, Moist & Starry] - Can you guess which is which?" +
        "\n\n👨🏻‍🍳There's no public cooking area but there is a public BarnBQ Grill located on the Beach." +
        "\n👨🏻‍🍳You can also buy Tier 1 Stoves for 100 coins from the store, and place them inside your Speck's house for easy beginner cooking." +
        "\n👨🏻‍🍳Stoves cannot be placed outside of a house.",
    },
    forestry: {
      name: "Forestry",
      description:
        "Forestry is a Resource Skill that requires the use of an Axe to chop down trees. Drops from this skill can be useful in Woodworking and the Task Board." +
        "\n\nHere are some tips about Forestry that might help you as you start your woodcutting journey:" +
        "\n🪓You cannot cut down trees higher than your tier level even if you have the right tool" +
        "\n🪓Every cut costs 1.5 Energy" +
        "\n🪓If you try to cut down a tier 3 tree with a tier 2 Axe, it wont work - but you can cut a Tier 2 Tree with a Tier 3 Tool if you are high enough level to use the T3 Tool" +
        "\n🪓Trees can only be cut in stump form and in fully grown form (Much different from before where there were 3 cutting phases.)" +
        "\n🪓Trees drop the resources after you've cut them fully." +
        "\n🪓The amount of chops vary depending on RNG. Sometimes it only takes 1 hit to cut down a tree. Sometimes it takes 11. And that number can be anything in between. It should average out to 6 cuts over time." +
        "\n🪓You only get EXP if you collect the drops from the trees. The drops hold the EXP, not the tree cuts itself" +
        "\n\n🪓There are trees scattered around Terra Villa and the Carnival grounds that you can cut publicly" +
        "\n🪓You can also buy Tier 1 Trees for 100 coins from the store, and place them on your land for easy early-game leveling." +
        "\n🪓Trees cannot be placed inside of a house.",
    },
    woodwork: {
      name: "Woodworking",
      description:
        "Woodworking is a Crafting Skill that mostly uses the drops from Forestry to craft items. There's nothing particularly special about these items, but can be helpful for completing tasks on the Task Board. This is also the skill you want to level if you'd like to craft your own storage chests" +
        "\n\nHere are some tips about woodworking that might help you as you start your woodworking journey:" +
        "\n🪚You can use higher woodworking benches even if you haven't reached the appropriate tier. So there's no limitation on which bench you can use" +
        "\n\n🪚There's one public woodworking bench on the left side of the Hot Air Balloon (center Terra Villa)" +
        "\n🪚You can also buy Tier 1 Woodworking Benches for 100 coins from the store, and place them on your speck for easy beginner woodworking." +
        "\n🪚Woodworking Benches can be placed inside and outside",
    },
    mining: {
      name: "Mining",
      description:
        "Mining is a Resource Skill that requires the use of a Pick Axe to gather resources from various mines. Drops from this skill can be useful in Metalworking, Stoneshaping and the Task Board." +
        "\n\nHere are some tips about Mining that might help you as you start your mining journey:" +
        "\n⛏️You cannot use Mines higher than your tier level even if you have the right tool" +
        "\n⛏️It costs 4 Energy to Mine [Even if it fails] on every mine" +
        "\n⛏️If you try to use a lower tier tool on a higher tier mine, it wont work - but you can use a higher tier tool on a lower tier Mine if you are high enough level to use the T3 Tool" +
        "\n⛏️Mining has a percentage chance of being successful. Every other time you'll see the Mine rejects your attempt. Keep trying until it has the 2 minute timer." +
        "\n⛏️If you use a higher tier tool on a lower level mine, the chances are increased for success" +
        "\n⛏️You get EXP from a Mine when the resources 'pop out' - You don't get them when you collect them from the mine. If you aren't on the land when the resources pop out, then you get the EXP when you go back to the land." +
        "\n\n⛏️There is no public mine" +
        "\n⛏️You can also buy Tier 1 Mines for 100 coins from the store, and place them on your land for easy early-game leveling." +
        "\n⛏️Mines cannot be placed inside of a house.",
    },
    stoneshaping: {
      name: "Stoneshaping",
      description:
        "Stoneshaping is a Crafting Skill that mostly uses the drops from Mining to craft items. There's nothing particularly special about these items, but they can be helpful for completing tasks on the Task Board." +
        "\n\nHere are some tips about stoneshaping that might help you as you start your journey:" +
        "\n🪨You can use higher stoneshaping benches even if you haven't reached the appropriate tier. So there's no limitation on which bench you can use" +
        "\n🪨All benches require wood before they can operate. The wood lasts for 1 hour, and you can add more wood to stack time (2 logs for 2 hours, etc)" +
        "\n\n🪨There's one public stoneshaping bench on the left side of the Pixel HQ (walk top left)" +
        "\n🪨You can also buy Tier 1 Stoneshaping Benches for 100 coins from the store, and place them on your Speck for easy beginner stoneshaping." +
        "\n🪨Stoneshaping Benches can be placed inside and outside",
    },
    metalworking: {
      name: "Metalworking",
      description:
        "Metalworking is a Crafting Skill that mostly uses the drops from Mining to craft items. There's nothing particularly special about these items, but they can be helpful for completing tasks on the Task Board." +
        "\n\nHere are some tips about metalworking that might help you as you start your journey:" +
        "\n🫙You can use higher metalworking benches even if you haven't reached the appropriate tier. So there's no limitation on which bench you can use" +
        "\n\n🫙There's one public metalworking bench on the right side of the Pixel HQ (walk top right)" +
        "\n🫙You can also buy Tier 1 metalworking Benches for 100 coins from the store, and place them on your Speck for easy beginner metalworking." +
        "\n🫙Metalworking Benches can be placed inside and outside",
    },
    petcare: {
      name: "Animal Care",
      description:
        "Animal Care can be a mix of crafting and resource industries. None of these seem to appear on the task board. Every skill that has animals involved will most likely increase this ability's exp. So think bees, slugs, chickens, and even crafting pet treats" +
        "\n🐰You get 1 Pet care EXP for caring for your pet (making it happy above 70% after it has dropped into an unhappy state)." +
        "\n🐄There's no EXP for feeding the cow but it drops Cow Turd after every feeding which can be used in the composter to make Fertilizer. It also has a chance to drop 0-3 Milk which is used in other recipes." +
        "\n🐄The cow eats and digests for 1hr 20 minutes" +
        "\n🐝Bees can be purchased in the Pet Shop and placed on an Apiary where it will produce resources every 45 minutes" +
        "\n🐌Slugs can be purchased in the Pet Shop and placed on a Slug Hutch where it will produce resources every hour" +
        "\n🐔Chickens can be purchased in the Pet Shop and placed on a Coop where it will produce resources every hour" +
        "\n\n🫏Public Animal Care Industries are located on the right side of Terra Villa (Apiary, Coop, Slug Hutch, Composter and Cow)",
    },
    business: {
      name: "Business",
      description:
        "Business has a mix of Crafting Industries but none of them seem to appear on the task board, similar to Animal Care." +
        "\n⚙️The Machine located inside of the Ministry of Innovation (bottom left of the Fountain) has many of the recipes for Business." +
        "\n🍷The Winery located at the bottom left of the map is also a source of recipes for the Business Skill. You can only use it if you're VIP" +
        "\n🧋The Latte and Milkshake Machines currently don't exist on the map since Barney's Bazaarn" +
        "\n\n🕹️There is also a non-Pixels way to raise those Business Skills via NuCyber. Nucyber's manufacturing and packaging abilities grant you Business EXP which translates to Pixels Exp" +
        "\nI'll dive into Nucyber's skills below because I wont go into them any further anywhere else.\n",
    },
    exploration: {
      name: "Exploration",
      description:
        "Exploration is an unused skill currently. There have been a few cases of EXP gain when collecting fish in the dungerons, but nothing consistent." +
        "\n🔍Hopefully it's a skill that will be used more in the future when Contestable Lands come in to play, or perhaps Gemcrafting (●'◡'●)\n\n\n\n\n\n",
    },
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    navigate(`#${category.toLowerCase()}`);
  };

  return (
    <div className="container mx-auto mt-5 p-6 rounded-lg shadow-lg bg-retrodp relative">
      <div className="relative z-10">
        <h1 className="text-6xl font-heading mb-3 text-center text-retroegg">Skills</h1>
        <div className="flex justify-center items-start min-h-fit">
          {["Skills", "Levels", "Breakdown"].map((category) => (
            <button
              key={category}
              className={`py-1 px-4 m-2 rounded-full ${selectedCategory === category ? "bg-retropurple text-white" : "bg-retrosilver text-gray-700"}`}
              onClick={() => handleCategoryClick(category)}>
              {category}
            </button>
          ))}
        </div>

        <div className="guide-content space-y-4">
          {selectedCategory === "Skills" && (
            <section id="skills" className="mb-6">
              <div className="bg-retropb rounded-md p-2 mt-2">
                <h2 className="starterTitle">About Skills</h2>
                <div className="starterText">
                  <p>
                    There are currently 10 different Skills in the Pixels Game. We can break these down into 3 different categories:
                    <br />
                    <br />✨<b>Resource Skills</b> - These are the industries that you use and get an item back such as farming (you use seeds, and get back grown crops), or mining
                    (you use a pick and you get back mined resources). You will also see these items used in the task board or in available recipes
                    <br />✨<b>Crafting Skills</b> - These are industries where you can craft something, like cooking or stoneshaping. You take raw resources or other crafted
                    items, and make a completely new item with them. You will see these items used in the Task Board or in events.
                    <br />✨<b>Irrelevant Skills</b> - These are industries that are not seen in the Task Board or in any other event - they can still be useful, but I will
                    consider them irrelevant (¬_¬")
                    <br />
                    <br />
                    Each skill requires the same amount of EXP to get to the higher levels, and each skill represents different industries, items or profit. Some skills might be
                    better to focus on than others.
                    <br />
                    For example, going to the Pixels Discord, you will always see people saying don't level cooking. If you enjoy cooking, then level it! If you don't enjoy it and
                    you're just here for the task board, then don't level cooking.
                    <br />
                    <br />
                    These are how I break down the skills, especially when referencing them at different parts of the website:
                    <br />
                    <br />
                  </p>
                  <table className="table1">
                    <thead>
                      <tr>
                        <th>Skills</th>
                        <th>Category</th>
                        <th>
                          Related
                          <br />
                          Industries
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowSpan="2">Farming</td>
                        <td rowSpan="2">Resource</td>
                        <td>Crop Planting</td>
                      </tr>
                      <tr>
                        <td>Windmill</td>
                      </tr>
                      <tr>
                        <td rowSpan="2">Cooking</td>
                        <td rowSpan="2">Crafting</td>
                        <td>Cooking Stoves</td>
                      </tr>
                      <tr>
                        <td>Grills (Land, Water and Space BBQ - not pet treats)</td>
                      </tr>
                      <tr>
                        <td>Forestry</td>
                        <td>Resource</td>
                        <td>Trees</td>
                      </tr>
                      <tr>
                        <td>Woodworking</td>
                        <td>Crafting</td>
                        <td>Woodworking Benches</td>
                      </tr>
                      <tr>
                        <td>Mining</td>
                        <td>Resource</td>
                        <td>Mines</td>
                      </tr>
                      <tr>
                        <td>Metalworking</td>
                        <td>Crafting</td>
                        <td>Metalworking Benches</td>
                      </tr>
                      <tr>
                        <td>Stoneshaping</td>
                        <td>Crafting</td>
                        <td>Stoneshaping Benches</td>
                      </tr>
                      <tr>
                        <td rowSpan="4">Animal Care</td>
                        <td rowSpan="4">Irrelevant</td>
                        <td>Coops</td>
                      </tr>
                      <tr>
                        <td>Slug Hutches</td>
                      </tr>
                      <tr>
                        <td>Apiaries</td>
                      </tr>
                      <tr>
                        <td>Pet Care (and making pet treats)</td>
                      </tr>
                      <tr>
                        <td rowSpan="5">Business</td>
                        <td rowSpan="5">Irrelevant</td>
                        <td>Winery</td>
                      </tr>
                      <tr>
                        <td>The Machine (MOI)</td>
                      </tr>
                      <tr>
                        <td>Textile Mill</td>
                      </tr>
                      <tr>
                        <td>Latte and Milkshake Machines</td>
                      </tr>
                      <tr>
                        <td>Juicer</td>
                      </tr>
                      <tr>
                        <td>Exploration</td>
                        <td>Irrelevant</td>
                        <td>Nothing (it's not in use)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="bg-retropb rounded-md p-2 mt-2">
                <h2 className="starterTitle">Tiers</h2>
                <div className="starterText">
                  <p>
                    {" "}
                    <strong>Tiers</strong> are how skills are broken up and categorized. You will see that as you level up, you unlock higher level items that require higher tier
                    tools.
                    <br />
                    As an example, at the lower tiers, you can use basic tools such as the Basic Pick to mine, Basic Axe to cut trees, Basic Soil to plant, Basic Shears to harvest,
                    etc.
                    <br />
                    When you start to use higher tier industries, you will need the equivalent tier or higher tier tools.
                    <br />
                    Every Tier 1 Kit & tool can be purchased at the store
                    <br />
                    <span className="underline">Currently Tier 5 is useless.</span>
                    <br />
                    <br />
                    Here is the breakdown of the Tiers and relevant industries/tools that you can expect to see in those tiers:
                  </p>
                  <table className="table1">
                    <thead>
                      <tr>
                        <th>Tier</th>
                        <th>Levels</th>
                        <th>Industries</th>
                        <th>Tools</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>1-19</td>
                        <td>
                          Basic Mine
                          <br />
                          Basic Soil
                          <br />
                          Basic Metalworking Kit
                          <br />
                          Basic Stoneshaping Kit
                          <br />
                          Basic Woodworking Bench
                          <br />
                          Whittlewood Tree
                          <br />
                          Basic Stove
                        </td>
                        <td>
                          Basic Pick
                          <br />
                          Basic Shears
                          <br />
                          Basic Axe
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>20-39</td>
                        <td>
                          Improved Mine
                          <br />
                          Enriched Soil
                          <br />
                          Improved Metalworking Kit
                          <br />
                          Improved Stoneshaping Kit
                          <br />
                          Improved Woodworking Bench
                          <br />
                          Craftbark Tree
                          <br />
                          Improved Stove
                        </td>
                        <td>
                          Bronzenite Pick
                          <br />
                          Bronzenite Shears
                          <br />
                          Bronzenite Axe
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>40-59</td>
                        <td>
                          Refined Mine
                          <br />
                          Fortified Soil
                          <br />
                          Refined Metalworking Kit
                          <br />
                          Refined Stoneshaping Kit
                          <br />
                          Refined Woodworking Bench
                          <br />
                          Sawstump Tree
                          <br />
                          Refined Stove
                        </td>
                        <td>
                          Irontite Pick
                          <br />
                          Irontite Shears
                          <br />
                          Irontite Axe
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>60-79</td>
                        <td>
                          Master Mine
                          <br />
                          Lush Soil
                          <br />
                          Master Metalworking Kit
                          <br />
                          Master Stoneshaping Kit
                          <br />
                          Master Woodworking Bench
                          <br />
                          Borebranch Tree
                          <br />
                          Master Stove
                        </td>
                        <td>
                          Adamaxium Pick
                          <br />
                          Adamaxium Shears
                          <br />
                          Adamaxium Axe
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>80-100</td>
                        <td>
                          Elite Mine
                          <br />
                          Verdant Soil
                          <br />
                          Elite Metalworking Kit
                          <br />
                          Elite Stoneshaping Kit
                          <br />
                          Elite Woodworking Bench
                          <br />
                          Hammeroot Tree
                          <br />
                          Elite Stove
                        </td>
                        <td>
                          Unobtainium Pick
                          <br />
                          Unobtainium Shears
                          <br />
                          Unobtainium Axe
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          )}

          {selectedCategory === "Levels" && (
            <section id="levels" className="mb-6">
              <div className="bg-retropb rounded-md p-2 mt-2">
                <h2 className="starterTitle">Known Levels in Pixels</h2>
                <div className="starterText">
                  <p>
                    Please note that this is just my own research and I could be incorrect about some of this information.
                    <br />
                    This is based on calculations I've done, and what I've seen within the game from players.
                    <br />
                    Will provide a calculator for levels & experience in{" "}
                    <Link to="/tools/calculator" className="text-retropink hover:text-retropb">
                      Calculator
                    </Link>
                  </p>
                  <table className="table1 text-center">
                    <thead>
                      <tr>
                        <th>Level</th>
                        <th>
                          Total
                          <br />
                          Experience
                        </th>
                        <th>Exp to Level Up</th>
                      </tr>
                    </thead>
                    <tbody>
                      {levels &&
                        levels.map((level) => (
                          <tr key={level.level}>
                            <td>{level.level}</td>
                            <td>{level.exp.toFixed(2)}</td>
                            <td>{level.nextLevelExp.toFixed(2)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          )}

          {selectedCategory === "Breakdown" && selectedSkill && (
            <section id="skillbreakdown" className="mb-6">
              <div>
                <div className="flex flex-wrap justify-center gap-4 mb-6 mt-3">
                  {Object.keys(skills).map((key) => (
                    <button
                      key={key}
                      onClick={() => setSelectedSkill(key)}
                      className={`p-2 rounded-lg border border-2 ${selectedSkill === key ? "border-retropb bg-retropb" : "border-gold bg-retrobg hover:bg-retrogray"} transition`}>
                      <img src={`/images/skills/${key}.png`} alt={skills[key].name} className="w-5 h-5 md:w-10 md:h-10 object-contain" />
                    </button>
                  ))}
                </div>

                {selectedSkill && (
                  <div className="skill-details p-4 bg-retroegg rounded-lg shadow-md">
                    <h3 className="text-4xl mb-2 text-retrogray">{skills[selectedSkill].name}</h3>
                    <div className="flex justify-between items-center mb-3">
                      <button onClick={toggleExpand} className="text-sm text-retropb hover:underline">
                        {isExpanded ? "No Details" : "See Details"}
                      </button>
                    </div>

                    {isExpanded && (
                      <div className="flex flex-col md:flex-row md:items-center gap-4">
                        <p className="whitespace-pre-line starterText">{skills[selectedSkill].description}</p>
                      </div>
                    )}

                    {isModalOpen && <ItemModal item={selectedItem} onClose={closeModal} />}

                    {/* Search Input */}
                    <div className="mb-1">
                      <input type="text" placeholder="Search items..." value={searchQuery} onChange={handleSearchChange} className="w-1/6 p-1 text-xs border border-gray-400 rounded-lg text-retropb" />
                    </div>

                    {/* Add the table for drop data here */}
                    {filteredData && filteredData.length > 0 && (
                      <div className="mt-2 starterText">
                        <h4 className="text-2xl mb-2">Resource Table</h4>
                        <div className="overflow-y-auto max-h-[400px]">
                          <table className="tablelb w-full text-retrocream">
                            <thead>
                              <tr>
                                {skillTables[selectedSkill].headings.map((header, index) => (
                                  <th key={index}>{header}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {filteredData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                  {Object.values(row).map((value, cellIndex) => (
                                    <td key={cellIndex}>{value}</td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}

                    {skills[selectedSkill].name === "Business" && (
                      <div className="bg-retrobg rounded-lg shadow-md p-4 mt-3 text-sm">
                        <span className="text-3xl text-retroegg font-heading">NuCyber</span> is a game built on top of the Pixels Engine, but does not really relate directly to
                        Pixels or its game loop. It's an outside game that you can choose to play or not. The original game was created by Llamaverse creator but when the creator
                        spent all the funds in a gambling session, he had to sell and the product was eventually sold to one of Pixels's well-known players.
                        <br />
                        <br />
                        The loop goes something like this:
                        <br />
                        ♾️Collect a Pop Chip from CyberBob (you do this every 4 hours - you have to click him once to collect your Pop Chip and once to get him to make another for
                        you)
                        <br />
                        ♾️Take the Pop Chip to the Van somewhere mid-town of Nucyber where they will scan the Pop Chip for Supplies (also every four hours and requires one click to
                        get your boxes, and one click to scan a Pop Chip)
                        <br />
                        ♾️Take your boxes to the Unboxing Station top right of NuCyber and unbox all the goodies - the drops are random
                        <br />
                        ♾️Use the goodies to manufacture products (to turn on the manufacturing machine in the sewer, you need Fuel which is a random drop)
                        <br />
                        ♾️When you have manufactured products, box them up using the Packaging Machine
                        <br />
                        ♾️Sell Boxed/Packaged Good to the Store which is in the Bottom Left of NuCyber
                        <br />
                        <br />
                        The loop is basic and can be completed as many times as you'd like.
                        <br />
                        <table className="tablelb text-retrocream">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Level</th>
                              <th>Materials</th>
                              <th>EXP</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Boots</td>
                              <td>0</td>
                              <td>
                                3 Leather
                                <br />2 Cloth
                              </td>
                              <td>80</td>
                            </tr>
                            <tr>
                              <td>Lighter</td>
                              <td>0</td>
                              <td>
                                3 Plastic
                                <br />1 Fuel
                              </td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>Magnet</td>
                              <td>0</td>
                              <td>4 Metal</td>
                              <td>70</td>
                            </tr>
                            <tr>
                              <td>Flashlight</td>
                              <td>2</td>
                              <td>
                                2 Metal
                                <br />2 Plastic
                                <br />1 Wire
                              </td>
                              <td>120</td>
                            </tr>
                            <tr>
                              <td>Medkit</td>
                              <td>3</td>
                              <td>
                                2 Plastic
                                <br />5 Cloth
                                <br />2 Wire
                              </td>
                              <td>150</td>
                            </tr>
                            <tr>
                              <td>Jacket</td>
                              <td>5</td>
                              <td>
                                6 Leather
                                <br />2 Cloth
                              </td>
                              <td>220</td>
                            </tr>
                            <tr>
                              <td>VR</td>
                              <td>8</td>
                              <td>
                                6 Metal
                                <br />6 Plastic
                                <br />3 Wire
                              </td>
                              <td>350</td>
                            </tr>
                            <tr>
                              <td>Laptop</td>
                              <td>10</td>
                              <td>
                                8 Metal
                                <br />4 Plastic
                                <br />5 Wire
                              </td>
                              <td>500</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </section>
          )}
        </div>
      </div>
      <BackToTopGlobal />
    </div>
  );
};

export default Skills;
