import React from 'react';
import pixelstips from '../assets/images/others/pixelstips.jpg';
import guildpals from '../assets/images/others/guildpals.jpg';
import luuu from '../assets/images/others/Luuu.jpg';
import rojan from '../assets/images/others/rojan.jpg';
import nealax1 from '../assets/images/others/Nealax1.jpg';
import aftermath from '../assets/images/others/aftermath.jpg';
import zombina from '../assets/images/others/zombina.jpg';
import squeegee from '../assets/images/others/squeegee.jpg';

const Others = () => {
  return (
    <div className="container mx-auto h-[78vh] mt-5 p-6 rounded-lg shadow-lg bg-retrodp overflow-scroll">
      <h1 className="text-4xl font-heading mb-6 text-center text-retroegg">Other Sites</h1>
      <div className='bg-retrogray rounded-lg border-white border p-2 flex-col justify-center'>
        <p>These are some sites/content creators that also specialize in helping with all information for Pixels. They are really great, and all bring their own zest to the ecosystem, so be sure to check them out!</p>
        <strong><h3 className="underline mt-5 text-2xl font-teko text-white">Websites</h3></strong><br/>
        <div className='flex'>
        <a href="https://pixels.tips" target="_blank" rel="noreferrer"><img src={pixelstips} alt="Pixels Tips" className="w-32 h-12 rounded-xl shadow-sm shadow-retrogray border-white border border-4 ml-2"/></a><br/>
        <a href="https://pixels.guildpal.com/" target="_blank" rel="noreferrer"><img src={guildpals} alt="Guild Pals" className="w-40 h-12 rounded-xl shadow-sm shadow-retrogray border-white border border-4 ml-2"/></a><br/>
        </div>
        <strong><h4 className="underline mt-5 text-2xl font-teko text-white">Twitch Streams</h4></strong><br/>
        <div className='flex'>
        <a href="https://www.twitch.tv/luuu" target="_blank" rel="noreferrer"><img src={luuu} alt="Luuu" className="w-16 rounded-full shadow-sm shadow-retrogray border-white border border-4 ml-2"/></a><br/>
        <a href="https://www.twitch.tv/rojankhzxr" target="_blank" rel="noreferrer"><img src={rojan} alt="Rojan (ES)" className="w-16 rounded-full shadow-sm shadow-retrogray border-white border border-4 ml-2"/></a><br/>
        </div>
        <strong><h4 className="underline mt-5 text-2xl font-teko text-white">Youtubers</h4></strong><br/>
        <div className='flex'>
        <a href="https://www.youtube.com/@nealax1" target="_blank" rel="noreferrer"><img src={nealax1} alt="Nealax1" className="w-16 rounded-full shadow-sm shadow-retrogray border-white border border-4 ml-2"/></a><br/>
        <a href="https://www.youtube.com/@Aftermath-TV" target="_blank" rel="noreferrer"><img src={aftermath} alt="Aftermath TV" className="w-16 rounded-full shadow-sm shadow-retrogray border-white border border-4 ml-2"/></a><br/>
        </div>
        <strong><h4 className="underline mt-5 text-2xl font-teko text-white">X</h4></strong><br/>
        <div className='flex w-full align-center'>
        <a href="https://x.com/zombinaaaaa" target="_blank" rel="noreferrer"><img src={zombina} alt="Zombina" className="w-16 rounded-full shadow-sm shadow-retrogray border-white border border-4 ml-2"/></a><br/>
        <a href="https://x.com/Squeegee_Plays" target="_blank" rel="noreferrer"><img src={squeegee} alt="Squeegee Plays" className="w-16 rounded-full shadow-sm shadow-retrogray border-white border border-4 ml-2"/></a><br/>
        </div>

        <div className="mt-5">Also make sure you check out the <a href="https://forms.monday.com/forms/3700ba69bc92f72fd83824e1c707d0c1?r=use1" target="_blank" rel="noreferrer" className="text-retropink hover:underline"> Content Creator Program</a> if you regularly make Pixels content!</div>
      
      </div>

    </div>
  );
}

export default Others;
