import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import pixelore from "../assets/images/pixeloreguide.png";
import BackToTopGlobal from '../components/BackToTop2';
import { questsData } from "../utils/questData";

const QuestGuides = () => {
  const [selectedQuest, setSelectedQuest] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedDropdownQuest, setSelectedDropdownQuest] = useState('');

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const handleQuestClick = (quest) => {
    setSelectedQuest(quest);
    setSelectedDropdownQuest(quest.id);
  };

  // Filter quests by status
  const availableQuests = questsData.quests.filter((quest) => quest.status === "available");
  const expiredQuests = questsData.quests.filter((quest) => quest.status === "expired");

  return (
    <div className="flex flex-col justify-center items-start md:h-[78vh]">
      {isMobile && (        
        <div className="w-full p-1 bg-retropb rounded-md mb-1">
          <div className="mb-5"><h1 className="text-center text-5xl">Quest Guides</h1></div>
          <select
            className="w-full p-1 rounded-md bg-retropb text-retrocream text-sm border-retrobg border"
            value={selectedDropdownQuest}
            onChange={(e) =>
              handleQuestClick(
                [...availableQuests, ...expiredQuests].find(
                  quest => quest.id === parseInt(e.target.value)
                )
              )
            }
          >
            <option value="" disabled>Select a Quest</option>
            <optgroup label="Available Quests">
              {availableQuests.map((quest) => (
                <option key={quest.id} value={quest.id}>
                  {quest.name}
                </option>
              ))}
            </optgroup>
            <optgroup label="Expired Quests">
              {expiredQuests
                .sort((a, b) => b.id - a.id)
                .map((quest) => (
                  <option key={quest.id} value={quest.id}>
                    {quest.name}
                  </option>
                ))}
            </optgroup>
          </select>
        </div>
      )}

      <div className={`container mx-auto p-6 rounded-lg shadow-lg bg-retrodp ${isMobile ? 'space-y-1 h-[90%]' : 'flex space-x-1'} h-[78vh] mt-5`}>
        {!isMobile && (
          <div className="quests-section w-1/3 overflow-auto">
            <h1 className="text-4xl font-heading mb-6 text-center text-retrocream">Quest Guides</h1>
            <div className="mb-6">
              <h2 className="text-2xl font-heading mb-4 text-retrocream">Available Quests</h2>
              <ul className="space-y-2">
                {availableQuests.map((quest) => (
                  <li key={quest.id} className="questHeading" onClick={() => handleQuestClick(quest)}>
                    {quest.name}
                  </li>
                ))}
              </ul>
              <h2 className="text-2xl font-heading mt-6 mb-4 text-retrocream">Expired Quests</h2>
              <ul className="space-y-2">
                {expiredQuests
                  .sort((a, b) => b.id - a.id)
                  .map((quest) => (
                    <li key={quest.id} className="questHeading1" onClick={() => handleQuestClick(quest)}>
                      {quest.name}
                    </li>
                  ))}
              </ul>
            </div>
            <img src={pixelore} alt="Pixelore" className="left-0 w-32 h-auto mb-2 ml-2 rounded-full border-single border-4 border-retrosilver" />
          </div>
        )}

        <div className="quest-details w-full lg:w-2/3 h-full overflow-auto">
          {selectedQuest ? (
            <div className="bg-retropb p-6 rounded-lg shadow-md">
              <h3 className="text-3xl font-heading mb-4">{selectedQuest.name}</h3>
              <p className="text-sm mb-1">
                <strong>Location:</strong> {selectedQuest.location}
              </p>
              <p className="text-sm mb-1">
                <strong>NPC:</strong> {selectedQuest.npc}
              </p>
              <p className="text-sm mb-3">
                <strong>Steps/Details:</strong>
                <br /> {parse(selectedQuest.description)}
              </p>
              <p className="text-sm mb-2">
                <strong>Reward: 🎁</strong> {parse(selectedQuest.reward)}
              </p>
              <p className="text-sm mb-2">
                <strong>Items Needed:</strong> {selectedQuest.items.join(", ")}
              </p>
              <p className="text-sm mb-2">
                <strong>Other Info: ❗</strong> {parse(selectedQuest.otherinfo)}
              </p>
              {selectedQuest.image &&
                selectedQuest.image.length > 0 &&
                selectedQuest.image.map((imgSrc, index) => (
                  <img key={index} src={imgSrc} alt={`Quest ${index + 1}`} className="w-42 h-auto rounded-lg mb-4" />
                ))}
            </div>
          ) : (
            <div className="text-center text-sm text-retroegg">Select a quest to see its details</div>
          )}
        </div>
      </div>
      <BackToTopGlobal/>
    </div>
  );
};


export default QuestGuides;
