import React from 'react';
import bgimage from '../assets/images/pixelbg.png';

const BackgroundWrapper = ({ children }) => {
    const backgroundImage = `url(${bgimage})`;
    const backgroundStyles = {
      backgroundImage: backgroundImage,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      minHeight: '100vh',
      width: '100%',
      zIndex: 0,
    };
  
    const overlayStyles = {
      content: '',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.85)', // Adjust the color and opacity as needed
      zIndex: -1,
    };
  
    return (
      <div style={backgroundStyles}>
        <div style={overlayStyles}></div>
        {children}
      </div>
    );
  };
  
  export default BackgroundWrapper;