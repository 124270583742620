import React, { useState, useEffect, useMemo } from "react";
import pixelore from "../assets/images/pixelore-halloween.jpeg";
import {  Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const Halloween = () => {
  const navigate = useNavigate();
  const { tabId } = useParams();
  const [activeTab, setActiveTab] = useState(tabId || "");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [lbData, setLbData] = useState([]);
  const [playerNameFilter, setPlayerNameFilter] = useState("");
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ldbResponse = await axios.get("https://api.pixelore.wiki/api/halloweenlb");
        // Calculate ranks and update state
        const playersWithRanks = ldbResponse.data.playersDescending.map((entry, index) => ({
          ...entry,
          rank: index + 1, // Assign rank starting from 1
        }));
        setLbData({ playersDescending: playersWithRanks });
      } catch (error) {
        console.log("Issue fetching data: ", error);
      }
    };

    if (activeTab === "hw-lb") {
      fetchData();
    }
  }, [activeTab]);

  // Filter players based on the search query
  const filteredPlayers = useMemo(() => {
    return lbData.playersDescending ? lbData.playersDescending.filter((entry) => entry.player.username.toLowerCase().includes(playerNameFilter.toLowerCase())) : [];
  }, [lbData, playerNameFilter]);

  const tabs = [
    {
      id: "summary",
      label: "Event Summary",
      content: `<h2 class="text-4xl font-eventHeading underline">Summary of the Event</h2>
      <br/>
      Play Maul-a-Skaul every 8 hours to earn teeth! The more teeth you earn, the better. <br/>
      After you've earned teeth, use them at the store in front of Denis (the right dentist chair)
      
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Event+Screenshots/%7B2623628A-4ED5-4183-922D-DC785995785D%7D.png" alt="Denis Station" width="500"/>
      <br/><br/>
      
      To craft one entire Ump-o-Thumper, you need <u>52 teeth</u><br/>
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Event+Screenshots/%7B679DA0D1-6DF8-4D11-AEFF-9E23A72D21E6%7D.png" alt="Denis Shop" width="450"/>
      <br/>
      When you have enough materials, head to the right side at Ouji and craft the Ump-O-Lantern<br/>
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Event+Screenshots/%7B9304E747-47DB-4726-8C7E-A73E31079CEE%7D.png" alt="Ump-O-Lantern Recipe" width="450"/>
      <br/>
      After you've gotten the Ump-o-Lantern, head to the left side with Stu and place the Ump-o-Lantern on his table. 
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Event+Screenshots/%7BB1AB1237-9AFC-454A-98BC-EB69B268C97D%7D.png" alt="Ump-o-Lantern Stu" width="300"/>
      <br/>You get one Ump-o-Thumper for free from Stu. And you can purchase more Ump-o-Thumpers for 13 $PIXEL. The free one degrades after 2 tries, so they're only good for 2 hits. Use it to crack open your Ump-o-Lantern!
      `,
    },
    {
      id: "hw-game1",
      label: "How to Play: Maul-a-Skaul",
      content: `<h2 class="text-4xl font-eventHeading underline">Maul-a-Skaul</h2>Earn 333 Points in Maul-a-Skaul to also earn some rep! (๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Event+Screenshots/image_2024-10-23_192504947.png" alt="Maul-a-Skaul"/>
      <br/>
      I scored 33 Points on my first round without a pet or fast shoes! I was able to score 40 points in another round! I continue to play without pet or shoes.
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Event+Screenshots/%7BC668145A-CFA2-4E28-B5F9-6A00E841E1FB%7D.png" alt="Score"/>
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Event+Screenshots/%7B3C765C42-9ACB-4DEF-9D30-219CFF5609FE%7D.png" alt="Score"/>`,
    },
    {
      id: "hw-game2",
      label: "How to Play: Haunted Harvest",
      content: `<h2 class="text-4xl font-eventHeading underline">Haunted Harvest</h2><img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Event+Screenshots/image_2024-10-23_192317174.png" alt="haunted harvest"/>`,
    },
    {
      id: "hw-game3",
      label: "How to Play: Barney's Bat-ch Catch",
      content: `<h2 class="text-4xl font-eventHeading underline">Barney's Bat-ch Catch</h2>
      Barney’s Bats are on the loose! Each day, 3 Bats will appear in new locations across Pixels. Find them before they disappear at day’s end. Collect all 27 Bats over 9 days to earn a special Halloween reward. Don’t miss a day—those Bats won’t wait!<br/><br/>
      They reset at 1pm PT every day! <br/><br/>I will post the new locations on my Twitter everyday! Follow me for notifications ヾ(＠⌒ー⌒＠)ノ
      `,
    },
    {
      id: "hw-currency",
      label: "Prizes, Lanterns and Boxes",
      content: `<h2 class="text-3xl font-eventHeading underline">Driller Thriller Box</h2>
      Place on the ground to unlock the box!
      <ul class="basicLists">
      <li>Unicorny</li>
      <li>Kloun</li>
      <li>Mumee</li>
      </ul>
      <h2 class="text-3xl font-eventHeading underline">Ump-o-Lantern</h2>
      Crack open the lanterns to unlock these prizes!
      <ul class="basicLists">
      <li>Vamp Costume</li>
      <li>Sea Raider Costume</li>
      <li>Spirit Costume</li>
      <li>Werewoof Costume</li>
      <li>Kloun Costume</li>
      <li>Zombee Costume</li>
      <li>Umpkin Wine</li>
      <li>Storage Coffin</li></ul>
      <h2 class="text-3xl font-eventHeading underline">Goodie Bags</h2>  
      <p>Can be purchased for 3 $PIXEL each, and have a chance to contain one of these prizes:</p>
      <ul class="basicLists"><li>Unicorny</li>
      <li>Kloun</li>
      <li>Mumee</li>
      <li>Barney D-40 (used on MOI machine)</li>
      <li>Umpkin Wine</li>
      <li>Storage Coffin</li>
      <li>Bone Hut 2</li>
      <li>Scarecrow</li>
      <li>Infini-Portable</li>
      <li>Fireplace</li>
      <li>Spooky Sludge Wallpaper</li>
      <li>Nightmare Drip Wallpaper</li>
      <li>Pet Coupons (Halloween Pets - 100)</li>
      <li>Winery (1)</li></ul>`,
    }
  ];

  useEffect(() => {
    setActiveTab(tabId || ""); // Set the tab based on the URL parameter
  }, [tabId]);

  const handleTabClick = (id) => {
    setActiveTab(id);
    navigate(`/game-wiki/events/${id}`); // Update the URL when a tab is clicked
  };

  const getContent = (id) => {
    const tab = tabs.find((tab) => tab.id === id);
    return tab ? tab.content : "Content Not Found";
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };


  return (
    <div className="bg-retropb rounded-md p-2 mt-2 relative">
      <h2 className="starterTitle">Halloween Event</h2>
      <div className="starterText">
        {/* Mobile toggle button */}
        <div className="block md:hidden">
          <button onClick={toggleSidebar} className="p-1 bg-retrogray text-white rounded text-xs m-2">
            {isSidebarOpen ? "-" : "+"}
          </button>
        </div>

        {/* Sidebar and Backdrop */}
        {isSidebarOpen && (
          <>
            {/* Backdrop to cover content when sidebar is open */}
            <div className="fixed inset-0 bg-black opacity-60 z-40" onClick={toggleSidebar}></div>

            {/* Sidebar */}
            <div className="fixed top-30 align-middle left-0 h-2/4 w-3/4 bg-retrogray text-white z-50 p-4 border-r border-gray-300" style={{ maxWidth: "250px" }}>
              <ul>
                {tabs.map((tab) => (
                  <li key={tab.id}>
                    <button
                      className={`tab-button ${tab.id === activeTab ? "active" : ""}`}
                      onClick={() => {
                        handleTabClick(tab.id);
                        toggleSidebar(); // Close sidebar after selecting a tab
                      }}>
                      {tab.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}

        <div className="flex">
          {/* Sidebar for desktop */}
          <div className="hidden md:block w-[200px] border-r border-gray-300 pr-2 mr-2">
            <ul>
              {tabs.map((tab) => (
                <li key={tab.id}>
                  <button
                    className={`tab-button w-full text-left p-2 rounded-md transition-colors duration-200 m-1 ${
                      tab.id === activeTab
                        ? "bg-gray-500 text-white" // Active tab styles
                        : "bg-retrobg text-white hover:bg-gray-400" // Inactive tab styles
                    }`}
                    onClick={() => handleTabClick(tab.id)}>
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {/* Content Area */}
          <div className="w-full md:ml-4">
            {activeTab ? (activeTab === "hw-lb" ? (
                <div className="overflow-auto h-[50vh]">
                  <h2 class="text-3xl font-eventHeading underline">Leaderboards</h2>
                  {/* Name Filter for Leaderboards */}
                  <input type="text" placeholder="Filter by player name" onChange={(e) => setPlayerNameFilter(e.target.value)} className="p-1 border rounded mb-2 w-60" />
                  {filteredPlayers.length > 0 ? (
                    <table className="tablelb w-full">
                      <thead>
                        <tr>
                          <th className="border px-4 py-2">#</th>
                          <th className="border px-4 py-2">Player Name</th>
                          <th className="border px-4 py-2">Points</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredPlayers
                          .filter((entry) => entry.player.username.toLowerCase().includes(playerNameFilter.toLowerCase()))
                          .map((entry, index) => (
                            <tr key={entry.player._id}>
                              <td className="border px-4 py-2">{entry.rank}</td>
                              <td className="border px-4 py-2 hover:text-retropink">
                                <Link to={`/lookup/players/${entry.player._id}`} target="_blank" rel="noreferrer">
                                  {entry.player.username}
                                </Link>
                              </td>
                              <td className="border px-4 py-2">{entry.value}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No players found</p>
                  )}
                </div>
              ) : (
                // Render content for other tabs
                <div dangerouslySetInnerHTML={{ __html: getContent(activeTab) }} />
              
            )) : (
              // Default content if no tab is selected
              <div>
                <img src={pixelore} alt="Pixelore" className="h-60 rounded-3xl" />
                <p className="text-lg mt-2">Please select a menu item on the left to view content.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Halloween;
