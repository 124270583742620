import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';
import 'moment-timezone'; // Ensure moment-timezone is imported

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fcaa10c58ff4a4973b61dc7dfbfb07b3d.elf.site%2F&w=caa10c58-ff4a-4973-b61d-c7dfbfb07b3d');
        const eventsData = response.data.data.widgets?.['caa10c58-ff4a-4973-b61d-c7dfbfb07b3d']?.data?.settings?.events;
        const fetchedEventTypes = response.data.data.widgets?.['caa10c58-ff4a-4973-b61d-c7dfbfb07b3d']?.data?.settings?.eventTypes;

        if (!eventsData || !fetchedEventTypes) {
          console.error('No events data or event types found');
          return;
        }

        const localTimezone = moment.tz.guess(); // Get the local time zone of the viewer

        const formattedEvents = eventsData.map(event => {
          const eventStart = moment.tz(`${event.start.date}T${event.start.time}`, event.timeZone);
          const eventEnd = moment.tz(`${event.end.date}T${event.end.time}`, event.timeZone);
          const eventType = fetchedEventTypes.find(type => type.id === event.eventType) || {};
          return {
            title: event.name,
            start: eventStart.clone().tz(localTimezone).toDate(),
            end: eventEnd.clone().tz(localTimezone).toDate(),
            description: event.description,
            image: event.image.url,
            color: eventType.categoryColor || '#3174ad', // Default color if not found
            buttonLink: event.buttonLink.value,
            buttonText: event.buttonText,
            eventType: event.eventType, // Include eventType to use in eventStyleGetter
          };
        });

        setEvents(formattedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  // Define eventStyleGetter outside of the useEffect hook
  const eventStyleGetter = (event) => {
    const liveStream = "#3b1f6e";
    const twitterSpace = "#00aced";
    const communityEvent = "#ff5722";
    const gameplayEvent = "#3b1f6e"; // Assuming "Gameplay" should use the same color as "Live Stream"

    let backgroundColor;
    switch (event.eventType) { // Assuming you want to use event.title for the name
        case "6368ba2a10821":
            backgroundColor = liveStream;
            break;
        case "6368ba2a10862":
            backgroundColor = twitterSpace;
            break;
        case "lr13yt3o":
            backgroundColor = communityEvent;
            break;
        case "lr15cv9b":
            backgroundColor = gameplayEvent;
            break;
        case "lsmco3rj":
            backgroundColor = gameplayEvent;
            break;
        default:
            backgroundColor = '#3174ad'; // Default color if no match
    }

    return {
        style: {
            backgroundColor, // Use the computed background color
            borderRadius: '0px',
            opacity: 0.8,
            color: 'white', // Set text color to white
            border: 'none',
        },
    };
};
  return (
    <div className="container mx-auto mt-2 p-6 rounded-lg shadow-lg bg-retrodp">
      <h1 className="text-6xl font-heading mb-6 text-center text-retroegg">Pixels Calendar</h1>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 650 }}
        views={['month', 'agenda',]}
        eventPropGetter={eventStyleGetter} // Apply custom styles to events
        className="bg-retrogray text-white p-2 rounded-lg" // Apply custom styles if needed
      />
    </div>
  );
};

export default MyCalendar;
