import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-date-fns"; // Import the date adapter
import zoomPlugin from "chartjs-plugin-zoom"; // Import the zoom plugin

// Register all components, including scales, controllers, elements, and plugins
Chart.register(...registerables, zoomPlugin);

const HistoricalPriceChart = ({ historicalPrices = [], currentPrice, currentAmount }) => {
  // Check if there are historicalPrices to display
  if (historicalPrices.length === 0) {
    return (
      <div>
        <h3 className="text-lg text-center">Historical Prices</h3>
        <p>No historical data available for this item.</p>
      </div>
    );
  }

  // Ensure all timestamps are valid Date objects
  const updatedPrices = [
    ...historicalPrices,
    {
      timestamp: new Date().toISOString(), // Ensure this is in UTC format
      price: parseFloat(currentPrice.toString().replace(/,/g, '')), // Convert to number
      counts: currentAmount,
    },
  ];

  // Prepare the data for the chart
  const data = {
    labels: updatedPrices.map((entry) => new Date(entry.timestamp)), // Ensure timestamp is a valid Date object
    datasets: [
      {
        label: "Price",
        data: updatedPrices.map((entry) => entry.price),
        borderWidth: 2,
        pointBackgroundColor: "#9575DE",   
        borderColor: '#9575DE',    
        borderJoinStyle: "round",
      },
    ],
  };

  // Chart options
  const options = {
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const date = new Date(updatedPrices[tooltipItems[0].dataIndex].timestamp);
            return date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString("en-US");
          },
          label: (tooltipItem) => {
            const price = tooltipItem.raw.toLocaleString();
            const dataIndex = tooltipItem.dataIndex;
            const count = updatedPrices[dataIndex]?.counts ? updatedPrices[dataIndex].counts.toLocaleString() : "N/A";
            return [`Price: ${price}`, `Amt: ${count}`];
          },
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x', // Pan along the x-axis only
        },
        zoom: {
          enabled: true,
          mode: 'x', // Zoom along the x-axis only
          drag: true, // Enable dragging to zoom
          limits: {
            x: {
              min: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).getTime(), // 30 days ago
              max: new Date().getTime(), // Current time
            },
          },
          rangeMin: {
            x: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).getTime(), // Minimum zoom is 30 days
          },
          rangeMax: {
            x: new Date().getTime(), // Maximum zoom is current date
          },
          // Allow zooming by scrolling the mouse wheel
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true, // Allow pinch zooming on touch devices
          },
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: updatedPrices.length <= 24 ? "hour" : "day", // Display by hour if less than a day's data
          tooltipFormat: updatedPrices.length <= 24 ? "MMM dd, HH:mm" : "MMM dd", // Show detailed time for shorter data
          displayFormats: {
            hour: "HH:mm", // Format for x-axis when displaying hours
            day: "MMM dd", // Format for x-axis when displaying days
          },
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
        },
        ticks: {
          source: "auto",
          autoSkip: true,
          maxTicksLimit: updatedPrices.length <= 24 ? 10 : 7, // Adjust tick limit based on the amount of data
        },
      },
      y: {
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          color: "rgba(255, 255, 255, 0.9)",
        },
      },
    },
    elements: {
      point: {
        radius: 2,
        pointStyle: 'rectRot',
        borderColor: '#9575DE',
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false, // This makes the vertical hover line appear
      includeInvisible: true,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };

  return (
    <div>
      <h3 className="text-xl text-center">Historical Prices</h3>
      <div
        style={{
          position: "relative",
          backgroundColor: "#2B2730",
          padding: "20px",
          borderRadius: "10px",
          maxHeight: "300px",
          overflow: "auto",
        }}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default HistoricalPriceChart;
