import React from 'react';
import sadpixelore from '../assets/images/sadpixelore.png';

const ComingSoon = () => {
  return (
    <div className="flex justify-center items-center min-h-fill mx-auto mb-10">
      <div className="relative p-12 bg-retrocream rounded-full shadow-lg border border-gray-300 flex flex-col items-center">
        <div className="absolute top-0 transform -translate-y-1/2 bg-retrocream px-4 py-2 rounded-full border border-gray-300">
          <h2 className="text-center text-3xl text-retropurple">This page is under construction</h2>
        </div>
        <img src={sadpixelore} alt='PixeLore&Pixels' className="w-60 h-60 rounded-full" />
        <div className="absolute bottom-0 transform translate-y-1/2 bg-retrocream px-4 py-2 rounded-full border border-gray-300">
          <h3 className="text-center text-3xl text-retropurple">We hope to have it here soon!</h3>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
