import React from 'react';
import ComingSoon from '../components/ComingSoon';

const Taskboard = () => {
  return (
    <div className="container mx-auto mt-10 p-6 rounded-lg">
      <ComingSoon />
    </div>
  );
}

export default Taskboard;
