import React, { useState } from "react";
import BackToTopGlobal from "../components/BackToTop2";
import { events } from "../utils/eventData";
import BarneysBazaarn from "../Live Ops/BarneysBazaarn";
import Halloween from "../Live Ops/Halloween";
import GuildWars from "../Live Ops/GuildWars";

const Events = () => {
  const [selectedCategory, setSelectedCategory] = useState("Guild Wars 2");
  const [expandedEventId, setExpandedEventId] = useState(null);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleEventClick = (eventId) => {
    setExpandedEventId(expandedEventId === eventId ? null : eventId);
  };

  return (
    <div className="container mx-auto mt-5 p-6 rounded-lg shadow-lg bg-retrodp">
      <h1 className="text-5xl font-heading mb-2 text-center text-retroegg">Events & Live Ops</h1>
      {["Past Events", "Barney's Bazaarn", "Halloween", "Guild Wars 2"].map((category) => (
        <button
          key={category}
          className={`py-2 px-2 m-2 rounded-full text-sm ${selectedCategory === category ? "bg-retropurple text-white" : "bg-retrosilver text-gray-700"}`}
          onClick={() => handleCategoryClick(category)}
        >
          {category}
        </button>
      ))}

      {selectedCategory === "Past Events" && (
        <div className="guide-content space-y-4 overflow-auto h-[57vh]">
          <section id="pastevents" className="mb-6">
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">List of Past Events</h2>
              <div className="starterText">
                <ul className="basicLists">
                  {events.map((event) => (
                    <li key={event.id} className="hover:text-retropink">
                      <button onClick={() => handleEventClick(event.id)}>{event.title}</button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {events.map((event) => (
              <div key={event.id} className={expandedEventId === event.id ? "block mt-5" : "hidden "}>
                <div className="bg-retropb rounded-md p-2 mt-2">
                  <h2 id={event.id} className="starterTitle">
                    {event.title}
                  </h2>
                  <p className="starterText">
                    <strong className="text-retropurple">Date Started:</strong> {event.datestarted}
                    <br />
                    <strong className="text-retropurple">Date Ended:</strong> {event.dateended}
                    <br />
                    <strong className="text-retropurple">Potential Prizes:</strong> {event.prizes}
                    <br />
                    <strong className="text-retropurple">Environment:</strong> {event.env}
                    <br />
                    <strong className="text-retropurple">Location:</strong> {event.location}
                    <br />
                    <strong className="text-retropurple">Currency:</strong> {event.currency}
                    <br />
                    <strong className="text-retropurple">How To Play:</strong> {event.howtoplay}
                    <br />
                    <strong className="text-retropurple">Other Information:</strong> {event.other}
                    <br />
                  </p>
                </div>
              </div>
            ))}
          </section>
        </div>
      )}

      {selectedCategory === "Barney's Bazaarn" && (
        <div className="guide-content space-y-4">
          <BarneysBazaarn />
        </div>
      )}

      {selectedCategory === "Halloween" && (
        <div className="guide-content space-y-4">
          <Halloween />
        </div>
      )}

      {selectedCategory === "Guild Wars 2" && (
        <div className="guide-content space-y-4">
          <GuildWars />
        </div>
      )}
      <BackToTopGlobal />
    </div>
  );
};

export default Events;
