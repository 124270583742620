import React from 'react';
import sadPixelore from "../assets/images/sadpixelore.png";

const NotFound = () => {
  return (
    <div className="flex container mx-auto mt-10 p-6 rounded-lg shadow-lg bg-retrodp text-sm h-[75vh]">
        <div className="starterText w-[60%] mx-auto my-auto align-middle">
      <h1 className="text-5xl font-bold text-center">404 - Page Not Found</h1>
      <p className="text-center mt-4">Sorry, the page you are looking for does not exist.</p>
      <img src={sadPixelore} alt="sadPixelore" className="w-[20%] rounded-xl mx-auto"></img>
    </div></div>
  );
};

export default NotFound;