export const events = [
  {
    id: "play-to-mint",
    title: "Play-to-Mint",
    datestarted: "December 14th, 2021",
    dateended: "January 31, 2022",
    prizes: "Chance to Mint Pixels Land & Top 101 Won Golden Rakes",
    env: "Pixels Alpha",
    location: "Terra Villa",
    currency: "Not sure",
    howtoplay: "Complete Quests, earn points and try to be at the top of the leaderboard before the end of the event",
    other: "",
  },
  {
    id: "play-to-airdrop-1",
    title: "Play-to-Airdrop (1)",
    datestarted: "August 31, 2022",
    dateended: "October 31, 2022",
    prizes: "$BERRY, $PIXEL, Top 200 Won Sauna Portal",
    env: "Pixels Beta?",
    location: "Terra Villa",
    currency: "Coins",
    howtoplay: "Goal was to reach the top 20,000 in the leaderboard for a chance to receive an airdrop of $BERRY and $PIXEL",
    other: "",
  },
  {
    id: "tastemakerz",
    title: "Tastemakerz",
    datestarted: "April 19th, 2023",
    dateended: "May 15th, 2023",
    prizes: "$10,000 Worth of $BONDLY, Galactic Grills, Submarine Sizzlers and other prizes",
    env: "Tastemakerz",
    location: "Bored & Hungry Restaurant",
    currency: "$NANA",
    howtoplay: "Plant ingredients, craft burgers and grind to the top of the leaderboard",
    other: "This was a collaboration event with Forj & Tastemakerz",
  },
  {
    id: "otherspeck",
    title: "Otherspeck",
    datestarted: "September 6th, 2023",
    dateended: "October 4th, 2023",
    prizes: "$10,000 worth of prizes, including a MAYC - other prizes included ",
    env: "Otherspeck",
    location: "Otherspeck",
    currency: "$OTHER",
    howtoplay: "Craft Precious Gems with all your energy for $OTHER. Be at the top of the $OTHER leaderboard to win.",
    other: "Pixels made all 100,000 Other lands into Specks for the event. It was so cool!",
  },
  {
    id: "carnival",
    title: "Carnival",
    datestarted: "October 30th, 2023",
    dateended: "November 28th, 2023",
    prizes: "Genesis Pets, Apiaries, Wineries and many other smaller prizes",
    env: "Pixels",
    location: "Carnival",
    currency: "$BERRY",
    howtoplay: "Play Carnival Games and turn in tickets for chances to win",
    other: "",
  },
  {
    id: "mocaverse",
    title: "Mocaverse",
    datestarted: "December 22nd, 2023",
    dateended: "January 12th, 2024",
    prizes: "$80K worth of rewards: Realm Points, Farm Lands, Pixels Pets, Lucky Nekos, Smaller Prizes in Pixels",
    env: "Pixels",
    location: "Moca Clubhouse",
    currency: "$BERRY",
    howtoplay: "",
    other: "",
  },
  {
    id: "play-to-airdrop-2",
    title: "Play-to-Airdrop (2)",
    datestarted: "January 4th, 2024",
    dateended: "January 19th, 2024",
    prizes: "$PIXEL",
    env: "Pixels",
    location: "Pixels",
    currency: "$BERRY",
    howtoplay: "Raise your levels and make it to the top of leaderboards for a chance to be in the top 7000 that could make it into the LB",
    other: "This was V2 of the first play-to-airdrop for a lead-up to $PIXEL release",
  },
  {
    id: "cyberkongz",
    title: "CyberKongz",
    datestarted: "February 20th, 2024",
    dateended: "-",
    prizes: "???",
    env: "Pixels",
    location: "Niakea",
    currency: "Coins & $PIXEL",
    howtoplay: "Collect Kongnium, head to Niakea and catch Jumebaras",
    other: "not sure if this is really a live ops, but whatever",
  },
  {
    id: "guild-wars",
    title: "Guild Wars (I)",
    datestarted: "August 2nd, 2024",
    dateended: "August 5th, 2024",
    prizes: "$PIXEL - Top Guilds, and top players earned $PIXEL",
    env: "Pixels",
    location: "Caves",
    currency: "$PIXEL/Coins",
    howtoplay: "Battle against other guilds by planting, defending, and attacking the other guilds crops with Goo! More points you earn, the higher you get on the leaderboard",
    other: "First ever Guild Event! And Pixels's first profitable event.",
  },
  {
    id: "barneys-bazaarn",
    title: "Barney's Bazaarn (I)",
    datestarted: "September 12th, 2024",
    dateended: "September 17th, 2024",
    prizes: "$PIXEL for top 5000 Leaderboards - Golden Glam Cacha: Lands, Cow Pets, Cows, and Barney-related items",
    env: "Pixels",
    location: "Barney's Bazaarn",
    currency: "$PIXEL/Coins",
    howtoplay: "Create boxes to ship out to Barney's Clamazon Customers and earn points and green clams towards the leaderboard/Green Glam Gatcha. At the end of the event, players on the leaderboard are guaranteed prizes. Players with Golden Clams will earn other cool, fun rewards as well!",
    other: "",
  },{
    id: "halloween",
    title: "Halloween 2024",
    datestarted: "October 23rd, 2024",
    dateended: "October 31st, 2024",
    prizes: "Costumes, Energy Drinks, Winery, Pets, Game Items, Wallpaper, Energy Drinks, and Storage Coffins",
    env: "Pixels",
    location: "Guild House (halloween)",
    currency: "$PIXEL/Coins",
    howtoplay: "Play Maul-a-Skaul to collect teeth that can be used in the Dentist Office for prizes. Collect daily bats leading up to a final prize",
    other: "",
  },{
    id: "guildwars2",
    title: "Guild Wars Season 2",
    datestarted: "December 10th, 2024",
    dateended: "March 4th, 2025",
    prizes: "$PIXEL, In-game items, Guild Runes, More Info Coming Soon",
    env: "Pixels",
    location: "Caves Under the Guild House",
    currency: "$PIXEL/Coins/Guild Runes",
    howtoplay: "Battle against other guilds by planting, defending, and attacking the other guilds crops with Goo! More points you earn, the higher you get on the leaderboard",
    other: "First ever Guild Event! And Pixels's first profitable event.",
  },
];
