import React, { useState, useEffect } from "react";
import teller from "../assets/images/pixeloreteller.jpeg";
import landIcon from "../assets/images/icons/Land.png";
import playerIcon from "../assets/images/icons/Player.png";
import skillIcon from "../assets/images/icons/Skills.png";
import ComingSoon from "../components/ComingSoon";
import PLOT from "../assets/images/Maps/PLOT.png";
import rainbowRoad from "../assets/images/Maps/rainbow-road.png";
import BackToTopGlobal from "../components/BackToTop2";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const StarterGuide = () => {
  const { category } = useParams(); // Grab category from URL
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("Introduction");;
  const [expandedSection, setExpandedSection] = useState(null);
  const [totalCirculatingSupply, setCirculatingSupply] = useState(0);
  const [treasuryValue, setTreasuryValue] = useState(0);
  const [advisorValue, setAdvisorValue] = useState(0);
  const [avatarData, setAvatarData] = useState([]);
  const [isAvatarsVisible, setAvatarsVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const avatarResponse = await axios.get("https://api.pixelore.wiki/api/avatars");
        // Extract relevant avatar trustPoints data
        const trustPointsData = avatarResponse.data
        .filter((avatar) => avatar.trustPoints) // Skip avatars without trustPoints
        .map((avatar) => ({
          customName: avatar.customName || avatar.id,
          minNftsOwned: avatar.trustPoints.minNftsOwned,
          maxPoints: avatar.trustPoints.maxPoints,
          slope: avatar.trustPoints.slope,
        }))
        .sort((a, b) => b.slope - a.slope); // Sort by slope in descending order      
        setAvatarData(trustPointsData);
      } catch (error) {
        console.log("Issue fetching avatar data: ", error);
      }
    };

    if (selectedCategory === "Reputation") {
      fetchData();
    }
  }, [selectedCategory]); //fetches avatar data

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const handleCategoryClick = (newCategory) => {
    setSelectedCategory(newCategory);
    navigate(`/game-wiki/starter/${encodeURIComponent(newCategory)}`); // Update the URL
  };

  // Ensure selected category is in sync with the URL category on load or URL change
  useEffect(() => {
    if (category && category !== selectedCategory) {
      setSelectedCategory(category); // Update state if URL category changes
    }
  }, [category, selectedCategory]);

  useEffect(() => {
    // Initialize with TGE unlocked tokens
    let circulatingSupply = 0;

    // Date calculation
    const startDate = new Date("2024-02-19");
    const currentDate = new Date();
    const monthsSinceStart = Math.floor((currentDate - startDate) / (1000 * 60 * 60 * 24 * 30)); // Approximate month calculation

    // TGE Unlocked tokens
    circulatingSupply += 350000000; // Binance Launchpool
    circulatingSupply += 250000000; // Alpha Rewards
    circulatingSupply += 50000000; // Liquidity
    circulatingSupply += 475000000 * 0.157; // Advisors unlocked at TGE
    circulatingSupply += 1700000000 / 60; // Ecosystem unlocked at TGE
    circulatingSupply += 850000000 * 0.0204; //Treasury Unlocked at TGE

    // Monthly unlocked tokens after lockup
    if (monthsSinceStart > 12) {
      const vestingMonths = monthsSinceStart - 12;
      circulatingSupply += Math.min(vestingMonths, 36) * (700000000 / 36); // Investors
      circulatingSupply += Math.min(vestingMonths, 36) * (625000000 / 36); // Team
    }

    setTreasuryValue(Math.min(monthsSinceStart, 48) * (832660000 / 48));
    setAdvisorValue(Math.min(monthsSinceStart, 48) * (400425000 / 48));
    // Ecosystem continues vesting for 5 years (60 months)
    circulatingSupply += Math.min(monthsSinceStart, 60) * (1700000000 / 60); // Ecosystem monthly
    circulatingSupply += treasuryValue; //Treasury Monthly
    circulatingSupply += advisorValue; //Advisors Monthly

    setCirculatingSupply(circulatingSupply);
  }, [advisorValue, treasuryValue]);

  const content = [
    "Let's Begin Your Adventure in Pixels!",
    "Pixels is a Web3 Game that has been in the space for over 2 years! Started in 2020 as Banger.Digital, the original goal of Pixels was to be a product that would host online parties for large companies - a mix between Club Penguin & Zoom, but more fun!",
    "The team migrated to Mesh in early 2021, where the intention was to be an Online Events Platform but eventually that was discontinued, and along came Pixels.",
    "In late 2021, the team pivoted their resources into creating the Web3 game we now all know and love: starting off with the Land NFT, and teasing the beginning of the farming game inspired by Stardew Valley, Runescape and other farming/MMORPGs.",
    "Pixels launched its first ever Play-to-Mint where players grinded gameplay to get a chance of minting their very own Land NFT for 0.06 ETH (($160 USD!)) on January 31st 2022",
    "But the grind never stopped and the team of Pixels kept growing and building, with the aim to build out a game to rival Web2 games of its kind. New graphics, new quests, new adventures and then came the First Play-to-Airdrop launched in August 31 2022 which ran for 2 months ending on October 31 2022. Pixels boomed and garnered hundreds of thousands of players. It was one of, if not the biggest P2A ever!",
    "On November 16 2022, Pixels released Chapter 1 - starting the official Pixels game. Progression was wiped from all of the playtesting from the P2A, and the real grind began. $BERRY released on the same day, being the main currency of the Pixels Universe",
    "Within the month of November 2023, Pixels released its second NFT, along with its well-known Carnival event upon moving to Ronin.",
    "In January 2024, the game announced P2A part 2! Marking the final sprint to $PIXEL TGE. The excitement built in favor of the Pixels team, and in February of 2024, $BERRY was sunset, Coins were added to the game and $PIXEL was finally here! The game's official token had landed!",
    "Now, we've had many turbulent moments within the life cycle of Pixels, but one thing is for certain, the team never stopped building. Chapter 2 released in July 2024, and the grind has no intention to end!",
  ];

  return (
    <div className="container mx-auto mt-5 p-6 rounded-lg shadow-lg bg-retrodp">
      <h1 className="text-5xl font-heading mb-2 text-center text-retroegg">Starter Guide</h1>

      <div className="flex flex-wrap justify-center mb-4 space-x-2">
        {["About Pixels", "Introduction", "Basics", "VIP", "Reputation", "NPCs", "Inventory & Dailies", "Currencies", "Guilds"].map((cat) => (
          <button
            key={cat}
            className={`py-2 px-2 text-sm rounded-full m-1 ${selectedCategory === cat ? "bg-retropurple text-white" : "bg-retrosilver text-gray-700"}`}
            onClick={() => handleCategoryClick(cat)}>
            {cat}
          </button>
        ))}
      </div>

      <div className="guide-content space-y-4">
        {selectedCategory === "About Pixels" && (
          <section className="mb-6">
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Introduction</h2>
              {content.map((text, index) => (
                <div key={index} className={`flex items-start ${Math.floor(index / 2) % 2 === 0 ? "flex-row" : "flex-row-reverse"} space-x-4 space-x-reverse`}>
                  {index % 2 === 0 && <img src={teller} alt="PixeLoreTeller" className="w-24 h-24 rounded-full border-double border-1 border-retrosilver bg-retrocream" />}
                  <h2 className="starterText mt-2 mb-4">{text}</h2>
                </div>
              ))}
            </div>
          </section>
        )}

        {selectedCategory === "Introduction" && (
          <section className="mb-6">
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">What is Pixels?</h2>
              <p className="starterText">
                Pixels is a captivating Web3 Play-to-Earn and Play-to-Own MMO where you can grind, explore, complete missions or socialize in an open-world universe. The main
                currencies used within the game are Coins (off-chain) and $PIXEL (on-chain).
                <br />
                It is completely free-to-play and requires nothing from you except for you to create an account. Everything else is optional
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Who is the founder? And who are the faces of Pixels?</h2>
              <p className="starterText">
                <a target="_blank" rel="noreferrer" href="https://x.com/whatslukedoing">
                  Luke Barwikowski
                </a>{" "}
                is the founder of Pixels, and along with{" "}
                <a href="https://x.com/heidichristne" target="_blank" rel="noreferrer">
                  Heidi
                </a>
                , they are the faces of Pixels.
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Is this for me?</h2>
              <p className="starterText">
                That depends on your likes and dislikes. Are you <b>only</b> here to make money? Then this is probably not the game for you.
                <br />
                Are you here to make friends and relax? Do you enjoy social and repetitive tasks? Are you occasionally a fellow grinder who likes competition? Pixels offers all of
                these things. And continues to build out to so much more. Pixels has a lot to offer as a social game, and with their continued growth & success, it can only become
                much more.
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">What NFTs are in the game?</h2>
              <p className="starterText">
                Pixels has two NFTs. A Land NFT (find more information about them here) and a Pet NFT (find more information about them here). There are also many external NFTs
                integrated into the game for use as Avatars!
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">How do I earn & how much can I earn?</h2>
              <p className="starterText">
                This isn't a question I really like answering because this is still a game and that's my purpose as a player!
                <br />
                But for the sake of information... Players can currently earn via selling Coins or $PIXEL that they make in the game.
                <br />
                You can make coins and $PIXEL by completing tasks on the Task Board which is located in your house
                <br />
                Some players sell coins for FIAT or $PIXEL (this can't be done in the Pixels Discord) but it's really at your own risk... And if you have enough reputation to
                withdraw, $PIXEL can be withdrawn from the dashboard and sold
                <br />
                You might not make a lot for the day. But over time, you can build up an account that might earn way more than a new player!
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Is there an airdrop?</h2>
              <p className="starterText">
                Not really! The founder considers everyday at Pixels an airdrop, but there aren't any plans for more Airdrop events in the future.
                <br />
                The focus now is on major events and competitions, and continual work on making the game fulfilling and entertaining!
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">How big is the Pixels team?</h2>
              <p className="starterText">
                On AMAs, Luke & Heidi have said that the Pixels team is a small team of around 15 members, excluding the Community Team. That's art, design, marketing and devs!
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Can my entire family play?</h2>
              <p className="starterText">Yup! But there are some people who try to abuse the game and sign in on multiple accounts ( •̀ - •́ ) We don't like those people</p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">I have more questions!!</h2>
              <p className="starterText">
                I'm only one person (,,&gt;﹏&lt;,,) But I am interested in helping as many as I can! Use the 'Contact Me' form in the bottom right.
                <br />
                But please don't contact me about your bans! I am not on the Pixels team, and I cannot do anything about it.. I know it's frustrating, but I don't want my inbox
                flooded ദ്ദി ༎ຶ‿༎ຶ )
              </p>
            </div>
          </section>
        )}

        {selectedCategory === "Basics" && (
          <section className="flex flex-wrap justify-center mb-6 text-center">
            <p className="mini-section" onClick={() => toggleSection("Movement")}>
              Movement
            </p>
            {expandedSection === "Movement" && (
              <div className="expanded-section">
                <br />
                <hr className="prettyDivide" />
                <br />
                You can use your mouse, your arrow keys or WASD on your keyboard to move around.
                <br />
                <hr className="prettyDivide" />
                <br />
                Mobile players can use the joystick or can just hold down on a part of the map to move.
                <br />
                <hr className="prettyDivide" />
                <br />
                For players who don't have a QWERTY keyboard, there's no way to remap the buttons within the game ( •̯́ ^ •̯̀)
                <br />
                <hr className="prettyDivide" />
                <br />
                PC users: <br />
                <span className="text-2xl text-retropink">B</span> opens your bag
                <br />
                <span className="text-2xl text-retropink">C</span> opens chat
                <br />
                <span className="text-2xl text-retropink">Z</span> zooms the map
                <br />
                <span className="text-2xl text-retropink">ESC</span> closes most dialogs or deselects items
                <br />
                <span className="text-2xl text-retropink">TAB</span> cycles through your bag
                <br />
                <span className="text-2xl text-retropink">1-9</span> selects the first 9 items from your Bag
                <br />
                <span className="text-2xl text-retropink">Ctrl+H</span> hides all players from the map for 30 seconds
              </div>
            )}

            <p className="mini-section" onClick={() => toggleSection("Avatar Creation")}>
              Avatar Creation
            </p>
            {expandedSection === "Avatar Creation" && (
              <div className="expanded-section">
                <br />
                <hr className="prettyDivide" />
                <br />
                Click on the <img src={playerIcon} alt="PlayerIcon" className="icons" /> Player Icon and select "Change Avatar". From this menu, you can select an array of
                clothing, skin colors, facial styles, and accessories. If you purchased clothing and placed them in your wardrobe, they're all accessible from this menu! Kinda
                cool, if you ask me.
                <br />
                <hr className="prettyDivide" />
                <br />
                One other option for avatars is integrated NFTs! If you own an NFT that has been integrated by Pixels, you can use that NFT in-game as an avatar. From the same
                Player Menu, select <b>Choose NFT Avatar</b> and pick from the selection you have available. There are so many cool ones to choose from.
              </div>
            )}

            <p className="mini-section" onClick={() => toggleSection("Moving Between Lands")}>
              Moving Between Lands
            </p>
            {expandedSection === "Moving Between Lands" && (
              <div className="expanded-section">
                <br />
                <hr className="prettyDivide" />
                <br />
                There are three ways to get around to Pixel Lands. Well, four. But that fourth one is so tedious.
                <br />
                <hr className="prettyDivide" />
                <br />
                The first way is by using the Infiniportal. The Infiniportal is located in the PLOT building which is directly left of the hot air balloon.
                <div className="mapImage">
                  <img src={PLOT} width="400" height="500" alt="PLOT" />
                </div>
                <br />
                <hr className="prettyDivide" />
                <br />
                The second way is by using Bookmarks! You get 3 Bookmarks when you start that you can use on regular lands. After 3, you can only acquire more by getting VIP or
                buying NFT Land.
                <br />
                <br />
                To set a bookmark, you have to use method 1 first! Visit a land, and if you like that land, click on the Gate. From there, you should be able to bookmark. (Look at
                the picture below and see the part with the gate that has the number on it? Yeah that's the one.)
                <br />
                <br />
                Then to access the bookmark, click the <img src={landIcon} alt="LandIcon" className="icons" /> Land Icon top left, and your bookmarks will be there! Easy access.
                <br />
                <hr className="prettyDivide" />
                <br />
                The third way is with Infiniportables! They're like the Infiniportal, but they are an inventory item and you can take them with you. They're single-use though, so
                if you don't have any, you're out of luck. ( ´･･)ﾉ(._.`) You can get them from the Pixel HQ for 1 $PIXEL, or wait for an event to be able to craft them yourself.
                <br />
                <hr className="prettyDivide" />
                <br />
                And the fourth way to move between lands is by walking the Rainbow Road! That shiny multi-colored pathway that you see every time you visit Terra Villa? It connects
                all 5000 lands together! You can walk left or right, and meet more farm lands. But it sure is tiring to walk.
                <div className="mapImage">
                  <img src={rainbowRoad} alt="Rainbow Road" height="300" width="500" />
                </div>
              </div>
            )}

            <p className="mini-section" onClick={() => toggleSection("Skills")}>
              Skills
            </p>
            {expandedSection === "Skills" && (
              <div className="expanded-section">
                There isn't a total level factor for all of your skills in the game. Each individual skill impacts something different! And ideally, you only want to focus on a few
                good skills to succeed. You can see your own Skills by clicking the <img src={skillIcon} alt="Skill Icon" className="icons" /> Skill Icon in the top left.
                <br />
                <br />
                All of the skills are farming, cooking, forestry, woodworking, mining, metalworking, stoneshaping, petcare, business, and exploration. These skills can basically be
                partnered or be completely separate. For example, if you do a lot of farming, you might find cooking a pretty good partner skill.
                <br />
                <br />
                <ul>
                  <li>Farming ⌁ Cooking</li>
                  <li>Forestry ⌁ Woodworking</li>
                  <li>Mining ⌁ Stone Shaping or Metal Working</li>
                </ul>
                <br />
                <hr className="prettyDivide" />
                <br />
                Business, Petcare, and Exploration are all skills that are still being worked on and looped into the overall gameplay. Business involves things like Textiler and
                Winemaking. Pet Care involves caring for your pet, making them treats, or even tending to animals like Slugs and Chickens.
                <br />
                <br />
                Exploration.. well, we're still waiting on that one <br />
                (ó﹏ò｡)
              </div>
            )}

            <p className="mini-section" onClick={() => toggleSection("Trading & Marketplace")}>
              Trading/Market
            </p>
            {expandedSection === "Trading & Marketplace" && (
              <div className="expanded-section">
                <br />
                <hr className="prettyDivide" />
                <br />
                <b>Trading</b> is one of the simplest ways to get products and coins between players. You can trade items or coins to another account easily, by clicking on them
                and selecting "Trade".
                <br />
                <br />
                It's really easy and secure. The trade only completes when you've both locked in, and if anyone makes any changes, you need to Confirm again to make sure you agree
                with the changes! Always be diligent and keep an eye on what the other player is trading. While I love trusting people, people aren't always trustworthy.
                <br />
                <hr className="prettyDivide" />
                <br />
                And while trading is great, it is still very limited if you're a F2P player or if you haven't acquired <i>2000 reputation</i>. You can only trade up to 2000 coins
                unbalanced, and everything else must be either balanced, or you cannot trade. And what do I mean by balanced? Here let me give you an example.
                <br />
                <br />
                Let's say I want to sell 2000 popberries but I've already hit my trading limit. If popberries are valued at 2 Coins each, then I can only sell those 2000 popberries
                for 4000 Coins.
                <br />
                <br /> 4000 Coins for 2000 Popberries is equal in value, and therefore it is balanced. Does that make sense? I hope so!
                <br />
                <hr className="prettyDivide" />
                <br />
                <b>The Marketplace</b> is another great way to trade your materials for coins, or to buy something that you might need!
                <br />
                <br />
                The marketplace is public and definitely helps if you don't feel like trading direct with players.
                <br />
                <br />
                It is located inside of the Buck's Galore, which is the public store to the right of the Hot Air Balloon. Go to the back room, and you'll see a Buy and Sell station
                where you can list up to 5 items as a F2P player. If you're VIP, you can list 5 more, and being an NFT Land owner gives you 5 more as well (and these stack!).(≧◡≦)
              </div>
            )}
          </section>
        )}

        {selectedCategory === "VIP" && (
          <section className="mb-6">
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">What is VIP?</h2>
              <p className="starterText">
                VIP is a great way to put $PIXEL back into the ecosystem plus earn some extra benefits. It gives you some goodies in exchange for some $PIXEL.
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">What are the benefits?</h2>
              <p className="starterText">
                <ul className="basicLists">
                  <li>1500 Reputation Points</li>
                  <li>1 Extra Bookmark</li>
                  <li>Gold Name</li>
                  <li>VIP Lounge (for extra energy throughout the day!)</li>
                  <li>6 Extra Bag Spaces</li>
                  <li>More Task Board tasks</li>
                </ul>
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">How much does it cost?</h2>
              <p className="starterText">It costs about $10/month USD in $PIXEL. They've got it set up where it should auto-adjust everyday at midnight UTC.</p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Where do I get it?</h2>
              <p className="starterText">
                There's a fancy VIP booth in Terra Villa just southwest of the hot air balloon. You can get a 3-month, 6-month or 12-month VIP Pass. And remember you have to use it
                on yourself by clicking it in your bag, and then clicking on yourself!
                <br />
                VIP coupons are also tradeable and sold in the P2P Marketplace for Coins ヾ( ˃ᴗ˂ )◞ • *✰{" "}
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">What happens when it ends?</h2>
              <p className="starterText">
                Well! You lose your extra 6 backpack slots (the items get sent to your mailbox!), and your bookmark stays until you remove it and try to replace it with a new
                bookmark! You also lose the 1500 Reputation points that normally comes with VIP.
              </p>
            </div>
          </section>
        )}

        {selectedCategory === "Reputation" && (
          <section className="mb-6">
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">What is Reputation?</h2>
              <p className="starterText">
                Every account has reputation (also known as trust score). Reputation is something the team put in place on accounts to reward good players. <br />
                Players must build up their reputation via various tasks to be able to do things like trade, access the marketplace or withdraw.
                <br />
                Having a higher reputation usually means you're an active and good player. However the team's implementation of Reputation has not been the best.
                <br />
                Easily bottable quests and events caused reputation to not be so trustworthy, and has put reputation in a somewhat crappy spot.
                <br />
                <br />
                Check your reputation (trust score) on the dashboard
                <img src="https://f003.backblazeb2.com/file/Pixelore/Random+Images/image_2024-09-09_100424482.png" alt="Dashboard" width="200" />
                You can also check a breakdown of your reputation on your profile in{" "}
                <Link to="/lookup/players" className="text-retropink hover:text-retrogray" target="_blank">
                  Player Lookups.
                </Link>
                <br />
                <br />
                <strong>Reputation Breakdowns:</strong>
                <br />
                🪶 Withdraw Access: 1500 Reputation
                <br />
                🪶 Marketplace Access: 540 to Sell | 325 to Buy
                <br />
                🪶 Trading Access: Tier 1: 945 Rep | Coin Tier: 1,905 Rep | Unlimited: 3,000 Rep <br />
                🪶🪶🪶 Tier 1 means you can only trade balanced values. 2000 Coins can be unbalanced per month (look at Basics ➡️ Trading to get more information on trade limits)
                <br />
                🪶🪶🪶 Coin Tier: I think this means you can trade using coins but still balanced
                <br />
                🪶🪶🪶 Unlimited Tier: No trading limitations
                <br />
                🪶 Guild Creation Access: 2205 Reputation
                <br />
                <br />
                ⚠️ Unbalanced Trading: You lose reputation for unbalanced trading (if you do it a lot)
                <br />
                ⚠️ Restriction: -3000 Reputation
              </p>
            </div>

            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Reputation Marketplace/Trade Fees</h2>
              <div className="starterText">
                I am recording this as I discover the information -- If you know more info, DM me on Twitter!
                <br />
                This fee is charged on sale or trade, and is determined by the amount being spent + reputation of the player.
                <br />
                💹 To explain it more simply, if you sell an item on the Marketplace, you are charged a % of the amount as a fee. <br />
                🫱🏽‍🫲🏽If you trade coins, a % of the coins will be deducted based on the receiver's fee %.
                <br />
                Here is a table of data I have collected so far based on reputation to fees. It might just be an estimate for now so not fully accurate!
                <table className="table1">
                  <thead>
                    <tr>
                      <th>Tier</th>
                      <th>Reputation</th>
                      <th>Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>0 - 539 (also banned/restricted players)</td>
                      <td>30%</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>540 - 1500</td>
                      <td>18% - 20%</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>1500 - 2000</td>
                      <td>15% - 18%</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>2001 - 3000</td>
                      <td>10% - 15%</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>3001 - 3500</td>
                      <td>8% - 10%</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>3501 - 4000</td>
                      <td>7% - 8%</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>4001 - 4500</td>
                      <td>5% - 7%</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>4501 - 5000</td>
                      <td>1% - 5%</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>5001+</td>
                      <td>1%</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <b>UPDATE: </b> Existing VIPs will keep 1% fees until their expiration date, then they'll use the same fees as everyone else 📣
              </div>
            </div>

            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">How do I earn Reputation?</h2>
              <div className="starterText">
                Right now, we don't have a breakdown of the new reputation scores coming out, or how they are earned. I am going to do my best to keep my eyes peeled for this data,
                but for now this is what we know:
                <br />
                <br />
                🪶 VIP: 1500 Reputation
                <br />
                🪶 NFT Land Owner: Gives more reputation if you own more lands with the base rep being 1500
                <br />
                🪶 Completing quests
                <br />
                🪶 Owning Integrated NFTs <br />
                🪶 Participating in Live Ops Events <br />
                🪶 Owning Pet NFT (or Multiple)
                <br />
                🪶 Transactions
                <br />
                🪶 Playing the game (what does this mean? Being in a guild, participating in Task Board, Logging in? Unsure) <br />
                <br />
                <h1 className="text-2xl">The Past Reputation Items should still have the same Rep Points</h1>
                <h2 className="text-xl underline">These have been added since Chapter 2.5:</h2>
                🪶 NFT Land Ownership is 1500 Rep up to 4 Lands (so max: 6,000) <br />
                🪶 Bank Quest: 100 Reputation <br />
                🪶 Beginner Quests: 135 Reputation (for completing all) <br />
                🪶 Barney's Position Decision: 50 Reputation <br />
                🪶 Deranged Dental Exchange: 25 Reputation <br />
                🪶 Teeth Reputation: 50 Reputation <br />
                🪶 Barney's Bat-ch Catch: 50 Reputation <br /><br />

                It looks like players also lose reputation based on different factors. 
              
              </div>
            </div>

            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Avatar Reputation</h2>
              <div className="starterText">
              <button className="bg-retrogray text-xs rounded-lg p-1 text-white" onClick={() => setAvatarsVisible(!isAvatarsVisible)}>{!isAvatarsVisible ? "Expand" : "Collapse"}</button> Below are the details I could get for avatar reputation data
                <br/>
                {isAvatarsVisible ? (
                <table className="table1">
                  <thead>
                    <tr>
                      <th>Avatar Name</th>
                      <th>Min Needed</th>                      
                      <th>Rep/Avatar</th>
                      <th>Max Rep</th>
                      <th>Max Avatars</th>
                    </tr>
                  </thead>
                  <tbody>
                    {avatarData.map((avatar, index) => (
                      <tr key={index}>
                        <td>{avatar.customName}</td>
                        <td>{avatar.minNftsOwned}</td>
                        <td>{avatar.slope}</td>
                        <td>{avatar.maxPoints}</td>
                        <td>{(avatar.maxPoints / avatar.slope)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>) : ""} </div>
              </div>

            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Past Reputation Events</h2>
              <div className="starterText">
                <strong>These were Reputation Values Before Update October 2024:</strong>
                <br />
                Chapter 2:
                <br />
                🪶 Perry Page's Maze Quest: 400 Reputation
                <br />
                🪶 YGG Guild Quest #3: 200 Reputation
                <br />
                🪶 Shorelime Quest: 325 Reputation
                <br />
                🪶 Jihoz's Shake Break: 150 Reputation
                <br />
                <br />
                Chapter 1:
                <br />
                🪶 Flour Powder: 50 Reputation
                <br />
                🪶 Popberry Wine Delivery Service: 50 Reputation
                <br />
                🪶 Butterbrew's Delivery Service: 250 Reputation
                <br />
                🪶 Fuy Gieri BarbeQuest #2: 75 Reputation
                <br />
                🪶 Fuy Gieri BarbeQuest #3: 75 Reputation
                <br />
                🪶 Fuy Gieri BarbeQuest #4: 75 Reputation
                <br />
                <br />
                Before October 2024:
                <br />
                🪶 Account Age: log10(Account Age x 0.15)/40
                <br />
                Paid Options for increasing Reputation:
                <br />
                🪶 VIP: 1500 Reputation
                <br />
                🪶 NFT Land Owner: 1500 Reputation (activates after 7 days of holding land)
                <br />
                <br />
                Socials:
                <br />
                🪶 Discord: 100 Reputation
                <br />
                🪶 Email: 5 Reputation
                <br />
                🪶 Phone: 50 Reputation
                <br />
                🪶 Twitter: 50 Reputation
                <br />
              </div>
            </div>
          </section>
        )}

        {selectedCategory === "NPCs" && (
          <section className="mt-20">
            <ComingSoon />
          </section>
        )}

        {selectedCategory === "Inventory & Dailies" && (
          <section className="mb-6">
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Inventory & Storage</h2>
              <p className="starterText">
                Every player starts with 30 slots of inventory. You can add on 6 by getting VIP and another 6 if you're a land owner. Storage boxes can be crafted using the
                Woodworking Skill, so that you can have your own personal storage boxes on your speck or lands! Also, if you purchase clothes, or avatar accessories, you can store
                them in your wardrobe inside the house.
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Daily #1</h2>
              <p className="starterText">
                You can get daily login coins from the{" "}
                <a href="https://dashboard.pixels.xyz/" target="_blank" rel="noreferrer">
                  Dashboard
                </a>{" "}
                every day!
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Daily #2</h2>
              <div className="starterText">
                The Post Office has a special package for you every 24 hours with a possible chance to drop these things:
                <ul className="basicLists">
                  <li>12 Popberry Seeds</li>
                  <li>1 Energy Drink</li>
                  <li>5 Cooking Mix</li>
                  <li>1 Farm Charm Crystal</li>
                  <li>6 Fertilizer</li>
                  <li>10 Kongnium</li>
                  <li>1 Infiniportable</li>
                  <li>1 Pet Food</li>
                </ul>
              </div>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Free Storage #1</h2>
              <p className="starterText">Inside the Post Office has one chest where you can store 12 items!</p>
              <h2 className="starterTitle">Free Storage #2</h2>
              <p className="starterText">Inside the Drunken Goose, there's another 12-slot storage chest for keeping more things!</p>
              <h2 className="starterTitle">Free Storage #3</h2>
              <p className="starterText">
                This one's a little tricky, and kinda far. But if you go to the Moca Clubhouse from the Beach, and you have a Moca ID connected to your account on the Dashboard,
                you can access a large storage chest there! It stores way more than you might even need.
              </p>
              <h2 className="starterTitle">Free Storage #4</h2>
              <p className="starterText">
                Barney's Bazaarn has two chests. One with 30 Slots and the other with 6 Slots.
              </p>
            </div>
          </section>
        )}

        {selectedCategory === "Currencies" && (
          <section className="mb-6">
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Coins</h2>
              <p className="starterText">
                Coins are the in-game primary currency that you use to play the game. They will be the currency you see the most, and it is highly inflationary. You can't trade
                this currency outside of the game, and the primary use of it is to participate in game loops and buy items to progress within the game.
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">$PIXEL</h2>
              <div className="starterText">
                <p>
                  $PIXEL is the premium currency that should be used for improvement, quality of life, and pretty shiny things! Like a Racecar bed (yes that's real!). This token is
                  capped, and can be traded on Ronin, Binance and Coinbase for FIAT or other tokens/currencies
                </p>
                <br />
                <h2 className="text-4xl font-teko">$PIXEL Token Distribution</h2>
                <div className=" overflow-x-scroll">
                  <table className="table1">
                    <thead>
                      <tr>
                        <th>Distribution</th>
                        <th>Percentage</th>
                        <th>$PIXEL</th>
                        <th>
                          Unlocked
                          <br />
                          at TGE
                        </th>
                        <th>Monthly Value</th>
                        <th>Time Frame</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="2" className="font-bold text-right">
                          Total Supply:
                        </td>
                        <td colSpan="4" className="text-left font-bold">
                          5,000,000,000 $PIXEL
                        </td>
                      </tr>
                      <tr>
                        <td>Ecosystem</td>
                        <td>34%</td>
                        <td>1,700,000,000</td>
                        <td> 28,333,333.33</td>
                        <td>28,333,333.33</td>
                        <td>5 Years</td>
                      </tr>
                      <tr>
                        <td>Treasury</td>
                        <td>17%</td>
                        <td>850,000,000</td>
                        <td>{(850000000 * 0.0204).toLocaleString()}</td>
                        <td>{treasuryValue.toLocaleString()}</td>
                        <td>4 Year Vest (Advertised in Whitepaper as 1 Yr Lockup, 3 Year Vest)</td>
                      </tr>
                      <tr>
                        <td>Investors</td>
                        <td>14%</td>
                        <td>700,000,000</td>
                        <td>None</td>
                        <td>18,900,000</td>
                        <td>4 Year Vest (Advertised in Whitepaper as 1 Yr Lockup, 3 Year Vest)</td>
                      </tr>
                      <tr>
                        <td>Team</td>
                        <td>12.5%</td>
                        <td>625,000,000</td>
                        <td>None</td>
                        <td>17,361,111</td>
                        <td>1 Year Lockup, 3 Year Vest</td>
                      </tr>
                      <tr>
                        <td>Advisors</td>
                        <td>9.5%</td>
                        <td>475,000,000</td>
                        <td>{(475000000 * 0.157).toLocaleString()}</td>
                        <td>{advisorValue.toLocaleString()}</td>
                        <td>4 Year Vest (Advertised in Whitepaper as 1 Yr Lockup, 3 Year Vest)</td>
                      </tr>
                      <tr>
                        <td>Binance Launchpool</td>
                        <td>7%</td>
                        <td>350,000,000</td>
                        <td>350,000,000 </td>
                        <td>None</td>
                        <td>At TGE</td>
                      </tr>
                      <tr>
                        <td>Alpha Rewards</td>
                        <td>5%</td>
                        <td>250,000,000</td>
                        <td>250,000,000 </td>
                        <td>None</td>
                        <td>At TGE</td>
                      </tr>
                      <tr>
                        <td>Liquidity</td>
                        <td>1%</td>
                        <td>50,000,000</td>
                        <td>50,000,000</td>
                        <td>None</td>
                        <td>At TGE</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <h3 className="text-2xl font-teko">$PIXEL TGE Date: February 19th, 2024</h3>
                <h3 className="text-xl font-teko">Estimated Total Circulating Supply: {totalCirculatingSupply.toLocaleString()} $PIXEL</h3>
                <br />
                <div className="text-xs">
                  <p>📃 The Whitepaper says the Treasury is Locked for 1 Year and Vested for 3 Years, but it has been shown to not be the case!</p>
                  <p>
                    📃 Luke made a comment about one of the advisors getting a percentage at TGE - I quote: "they just wanted to have a higher % circulating supply at TGE and
                    they're trustworthy. All other advisors on a 1 year lockup, 3 year vest"
                  </p>
                  <p>
                    🗒️
                    <a href="https://cryptorank.io/price/pixels/vesting" target="_blank" rel="noreferrer" className="text-retropink hover:text-retrogray">
                      {" "}
                      This website
                    </a>{" "}
                    has a lot of super in-depth detail about the $PIXEL vesting. Should check it out!
                  </p>
                  <p>
                    🗒️
                    <a href="https://api.pixels.foundation/" target="_blank" rel="noreferrer" className="text-retropink hover:text-retrogray">
                      {" "}
                      This website
                    </a>{" "}
                    is the information from Pixels Foundation which differs from expected values!
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">$CYBER</h2>
              <p className="starterText">
                $CYBER is the in-game currency for the Nucyber Platform which is a game built on the Pixels Engine. It can be used for their game's primary game loop, but it cannot
                be traded, bought, sold or otherwise used.
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">$BERRY</h2>
              <p className="starterText">
                $BERRY was the first token on Pixels, and was sunset in February 2024 just before $PIXEL was released. The token was highly inflationary, and traded on the market.
              </p>
            </div>
          </section>
        )}

        {selectedCategory === "Guilds" && (
          <section className="mb-6">
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">What is a Guild?</h2>
              <p className="starterText">
                A guild is kinda like a group of people all aligned on the same gaming goals. Are you all grinders? Friends? Socializers? Money-makers? Things like that.. You want
                to find a guild where you fit right in.
                <br />
                In Pixels, Guilds have a few benefits - Guilds can lock down lands so only their guild members can use the land, and that becomes very helpful when you're looking
                for hard-to-find industries.
                <br />
                Also, guild competitions! They will be group events that you can only do with your guild. If you don't have one, well...you're going to miss out on that fun and
                reward.
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Do I need to join one?</h2>
              <p className="starterText">Nope~! You can play your entire adventure in Pixels without ever joining a guild. You could even make your own if you wanted to.</p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">How do I know if I am joining a good guild?</h2>
              <p className="starterText">
                There's never a guarantee. But you can ask around. Join their guild discord and see if their behaviour is something you align with. Always verify because you might
                be buying into something you definitely don't want to be a part of..
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Where do I start looking?</h2>
              <p className="starterText">
                {`Either the Pixels Discord, you can check in #guild-recruitment, or you can check the Guilds Page on the Dashboard, and search for something there. We have a ${(
                  <Link to="lookup/guilds/">Guild Page</Link>
                )} here as well so you can easily search for something that might fit your criteria ⸜(｡˃ ᵕ ˂ )⸝♡`}
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">What is the cost of joining a Guild?</h2>
              <p className="starterText">
                That depends on the guild! Every guild would have a different price depending on how many shards they've sold. Guilds with more shards sold cost more. Less shards,
                lower price. Find something in yourrrr budget!.
              </p>
            </div>
            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">What do I need to make one?</h2>
              <p className="starterText">
                15 $PIXEL, and a name.{" "}
                <a href="https://help.pixels.xyz/en/articles/9098771-creating-and-managing-a-guild" target="_blank" rel="noreferrer" className="myLinks">
                  This guide
                </a>{" "}
                by Pixels does an okay job of explaining
              </p>
            </div>
          </section>
        )}

        <BackToTopGlobal />
      </div>
    </div>
  );
};

export default StarterGuide;
