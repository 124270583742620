import React from 'react';
import { Link } from 'react-router-dom';
import collab from '../assets/images/pixelogo.png';


const Home = () => {
  return (
    <div className="flex justify-center items-center min-h-fit">
      <div>
      <div className="container mx-auto mt-10 mb-5 p-4 rounded-lg shadow-lg bg-retrodp">
      <div className="flex flex-wrap items-start space-x-4">
        <img src={collab} alt='PixeLore&Pixels' className="w-60 h-60 rounded-full border-double border-4 border-retrosilver bg-retrocream" />
        <h2 className=" text-2xl mt-4 mb-4 text-retrobg font-heading bg-retrocream p-4 rounded-lg shadow-md">
          Hello, I'm PixeLore!
          <br />↪ I'm here to guide you on your Pixels Adventure!
        </h2>
      </div>
      </div>
      <div className="container mx-auto mt-2 mb-5 p-4 rounded-lg shadow-lg bg-retrodp">
      <div className="quick-links mt-4">
        <h2 className="text-xl mb-1 font-chat text-retrocream">Here are some links to help you get started:</h2>
        <ul className="space-y-2">
        <li>
              <Link to="/game-wiki/starter" className="flex items-center space-x-2 bg-retropurple text-white py-2 px-4 rounded-full hover:bg-retrobg transition-colors duration-300">
                <i className="fas fa-hat-wizard"></i>
                <span>Starter Guide</span>
              </Link>
            </li>
          <li>
              <Link to="/game-wiki/quests" className="flex items-center space-x-2 bg-retropurple text-white py-2 px-4 rounded-full hover:bg-retroegg transition-colors duration-300">
                <i className="fas fa-map-signs"></i>
                <span>Quest Guides</span>
              </Link>
            </li>
            <li>
              <Link to="/lookup/items" className="flex items-center space-x-2 bg-retropurple text-white py-2 px-4 rounded-full hover:bg-retroegg transition-colors duration-300">
                <i className="fas fa-store"></i>
                <span>Item Search</span>
              </Link>
            </li>
            <li>
              <Link to="/game-wiki/skills" className="flex items-center space-x-2 bg-retropurple text-white py-2 px-4 rounded-full hover:bg-retroegg transition-colors duration-300">
                <i className="fas fa-pen"></i>
                <span>Skill Guides</span>
              </Link>
            </li>
            <li>
              <Link to="/lookup/players" className="flex items-center space-x-2 bg-retropurple text-white py-2 px-4 rounded-full hover:bg-retroegg transition-colors duration-300">
                <i className="fas fa-user"></i>
                <span>Player Lookups</span>
              </Link>
            </li>
          </ul>
      </div>
      </div>
    </div>
    </div>
  );
}

export default Home;
