import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const LandModal = ({ land, onClose, goToPreviousLand, goToNextLand, currentIndex, landLength }) => {
  const [player, setPlayer] = useState(null);
  const [perms, setPermissions] = useState(null);

  // Adds buttons to modal (esc, next, back)
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
      if (event.key === "ArrowLeft" && currentIndex > 0) {
        goToPreviousLand();
      }
      if (event.key === "ArrowRight" && currentIndex < landLength - 1) {
        goToNextLand();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose, goToPreviousLand, goToNextLand, currentIndex, landLength]);

  useEffect(() => {
    const getLandDetails = async () => {
      const num = land.landNum;
      const landResponse = await axios.get(`https://api.pixelore.wiki/api/landDetails/${num}`);
      if (landResponse) {
        setPlayer(landResponse.data.player);
        setPermissions(landResponse.data.permissions);
      }
    };
    getLandDetails();
  }, [land]);

  if (!land) return null; // If no item is selected, do not render the modal

  return (
    <div className="fixed inset-0 bg-retropb bg-opacity-60 flex justify-center items-center z-[150] text-white">
      <div className="bg-retropb p-6 rounded-lg shadow-lg w-full max-h-svh overflow-auto lg:max-w-[45%] text-sm border border-2 border-white">
        {/* Top Section */}
        <div className="mx-auto mb-4">
          <div className="text-center font-heading text-3xl">Farm #{land.landNum}</div>
          <div className="text-center font-heading text-3xl">{land.landName}</div>
        </div>

        {/* Iframe Section */}
        <div className="mb-4">
          <iframe
            src={`https://play.pixels.xyz/pixels/share/${land.landNum}`} // Assuming land has an iframeSrc property with the URL
            title="Land Details"
            width="100%" // Full width
            height="500" // Set a height based on your needs
            className="border-2 border-white rounded" // Add some styles
            allowFullScreen // Optional, allows full-screen mode
          />
        </div>

        {/*Player Details*/}
        <div>Owner: {player && player.username ? <Link to={`/lookup/players/${player._id}`}>{player.username}</Link> : "Owner does not have account"}</div>
        <div>
          Permissions:{" "}
          {perms && perms.use && perms.use.length > 0
            ? perms.use[perms.use.length - 1] === "ANY"
              ? "Public"
              : perms.use[perms.use.length - 1] === "Builder"
              ? "Public"
              : perms.use[perms.use.length - 1]
            : "No permissions"}
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between items-center mt-4">
          {/* Previous Button */}
          {typeof currentIndex === "number" && (
            <button
              onClick={goToPreviousLand}
              className={`px-2 py-1 rounded-lg hover:bg-retrodp ${currentIndex === 0 ? "opacity-50 cursor-not-allowed" : "bg-retropink"}`}
              disabled={currentIndex === 0} // Disable if on the first item
            >
              &larr; {/* Left Arrow */}
            </button>
          )}

          {/* Close Button */}
          <button onClick={onClose} className="px-2 py-1 bg-retropink text-white rounded-lg hover:bg-retrodp">
            Close
          </button>

          {/* Next Button */}
          {typeof currentIndex === "number" && (
            <button
              onClick={goToNextLand}
              className={`px-2 py-1 rounded-lg hover:bg-retrodp ${currentIndex === landLength - 1 ? "opacity-50 cursor-not-allowed" : "bg-retropink"}`}
              disabled={currentIndex === landLength - 1} // Disable if on the last item
            >
              &rarr; {/* Right Arrow */}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandModal;
