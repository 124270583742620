import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const setDocumentTitle = (pathname) => {
      switch (pathname) {
        case "/game-wiki/starter":
          return "Starter Guide - Pixels Wiki";
        case "/game-wiki/quests/":
          return "Quest Guides - Pixels Wiki";
        case "/game-wiki/taskboard":
          return "TaskBoard - Pixels Wiki";
        case "/game-wiki/land-specks/":
          return "Land/Speck Guide - Pixels Wiki";
        case "game-wiki/skills/":
          return "Skill Guide - Pixels Wiki";
        case "game-wiki/events/":
          return "Live Ops Guide - Pixels Wiki";
        case "game-wiki/pets/":
          return "Pet Guide - Pixels Wiki";
        case "/lookup/items":
          return "Item LookUp - Pixels Wiki";
        case "/lookup/players/":
          return "Player LookUp - Pixels Wiki";
        case "/lookup/players/:playerId":
          return "Individual Player - Pixels Wiki";
        case "/lookup/guilds":
          return "Guild LookUp - Pixels Wiki";
        case "/lookup/guilds/:handle":
          return "Guild Detail - Pixels Wiki";
        case "/tools/energy/":
          return "Energy - Pixels Wiki";
        case "/tools/calculator/":
          return "Calculator - Pixels Wiki";
        case "/tools/leaderboards":
          return "Leaderboard - Pixels Wiki";
        case "/other/assets":
          return "Pixel Game Assets - Pixels Wiki";
        case "/other/avatars":
          return "Avatars - Pixels Wiki";
        case "/other/calendar":
          return "Calendar - Pixels Wiki";
        case "/about":
          return "About Me - Pixels Wiki";
        case "/others":
          return "Others - Pixels Wiki";
        default:
          return "PixeLore - Pixels Wiki";
      }
    };

    // Set the document title based on the route
    document.title = setDocumentTitle(location.pathname);

    // Send the page view event to Google Analytics
    if (typeof window.gtag === "function") {
      window.gtag("config", "G-DCG7SB70VE", {
        page_path: location.pathname,
        page_title: document.title,
      });
    }
  }, [location]);

  return null; // This component doesn't render anything
};

export default GoogleAnalytics;
