import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';

// Function to get readable names from mapId
const getReadableName = (mapId, world, locations) => {

  if (mapId.startsWith('pixelsNFTFarm-')) {
    return `Farm #${mapId.split('-')[1]}`;
  } else if (mapId.startsWith('shareRent')) {
    return 'Speck Land';
  } else if (mapId.startsWith('shareInterior')) {
    return 'Speck House';
  } else if (mapId.startsWith('nftHouse')) {
    return `House #${mapId.split('e')[1]}`;
  } else if (mapId.startsWith('houseInterior')) {
    return `Basement #${mapId.split('or')[1]}`;
  }else {

    if(Array.isArray(locations)){
      if (Array.isArray(locations)) {
        // Find the location object with the matching mapId
        const location = locations.find(loc => loc.mapId === mapId);
    
        // Return the location name or a default message if not found
        return location ? `${location.name} World: ${world}` : `${mapId} World: ${world}` ;
      } else {
        // Return default message if locations is not an array
        return 'Unknown Location';
      }
    }
  }
};

const LocationInfo = ({ playerData }) => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get('https://api.pixelore.wiki/api/maps');
        setLocations(response.data);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    fetchLocations();
  }, []);
  
  const readableName = useMemo(() => {   
    let world = "";
    
    if (playerData?.current?.mapId) {
      if(playerData?.current?.world){
        world = playerData.current.world;
      }

      return getReadableName(playerData.current.mapId, world, locations);
    }
    return 'Offline';
  }, [playerData, locations]);

  const style = {
    color: readableName === 'Offline' ? 'indianred' : 'limegreen'
  };

  return (
    <p>
      <strong>Location:</strong> <span className="font-semibold" style={style}>{readableName}  </span>
    </p>
  );
};

export default LocationInfo;
