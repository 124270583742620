import { useState } from "react";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const [gotoPage, setGotoPage] = useState(currentPage);

  const handleGotoChange = (e) => {
    setGotoPage(e.target.value);
  };

  const handleGotoSubmit = () => {
    const page = Math.min(Math.max(Number(gotoPage), 1), totalPages); // Clamp page number between 1 and totalPages
    onPageChange(page);
  };

  // Fixed number of visible pages, e.g., 5 (2 before, 2 after current page)
  const visiblePages = 5;
  let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
  let endPage = Math.min(totalPages, startPage + visiblePages - 1);

  // Adjust startPage if we're at the end to ensure the correct number of buttons
  if (endPage - startPage + 1 < visiblePages) {
    startPage = Math.max(1, endPage - visiblePages + 1);
  }

  return (
    <div className="flex flex-col md:flex-row justify-between items-center mx-auto mt-2 w-[80%] text-sm">
    {/* Pagination Buttons */}
    <div className="flex justify-center mb-4 md:mb-0">
      {/* Previous and First Page Buttons */}
      {currentPage > 1 && (
        <>
          <button onClick={() => onPageChange(1)} className="mx-1 p-2 rounded bg-retrobabypink w-7 h-7 flex items-center justify-center" aria-label="First Page">
            «
          </button>
          <button
            onClick={() => onPageChange(currentPage - 1)}
            className="p-2 rounded bg-retrobabypink w-7 h-7 mx-[1px] flex items-center justify-center"
            aria-label="Previous Page">
            &lt;
          </button>
        </>
      )}
  
      {/* Page Number Buttons */}
      {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
        <button
          key={startPage + index}
          onClick={() => onPageChange(startPage + index)}
          className={`p-2 rounded ${startPage + index === currentPage ? "bg-retropurple text-white" : "bg-retrobg text-white"} w-7 h-7 mx-[1px] flex items-center justify-center`}
          aria-label={`Page ${startPage + index}`}>
          {startPage + index}
        </button>
      ))}
  
      {endPage < totalPages && (
        <>
          {endPage < totalPages - 1 && <span className="p-2 w-7 h-7 mx-[1px] flex items-center justify-center">...</span>}
          <button onClick={() => onPageChange(totalPages)} className="p-2 rounded bg-retrobabypink w-7 h-7 mx-[1px] flex items-center justify-center" aria-label="Last Page">
            {totalPages}
          </button>
        </>
      )}
  
      {/* Next and Last Page Buttons */}
      {currentPage < totalPages && (
        <>
          <button onClick={() => onPageChange(currentPage + 1)} className="p-2 rounded bg-retrobabypink w-7 h-7 mx-[1px] flex items-center justify-center" aria-label="Next Page">
            &gt;
          </button>
          <button onClick={() => onPageChange(totalPages)} className="p-2 rounded bg-retrobabypink w-7 h-7 mx-[1px] flex items-center justify-center" aria-label="Last Page">
            »
          </button>
        </>
      )}
    </div>
  
    {/* Go to Page Input */}
    <div className="flex flex-col items-center w-full md:flex-row md:w-auto md:ml-auto">
      <div className="flex items-center">
        <span className="mr-2">Go to page:</span>
        <input
          type="number"
          value={gotoPage}
          onChange={handleGotoChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleGotoSubmit(); // Call the submit function on pressing Enter
            }
          }}
          className="border p-1 w-16 text-center text-retrogray"
          min="1"
          max={totalPages}
        />
        <button
          onClick={handleGotoSubmit}
          className="ml-2 p-2 rounded bg-retropurple text-white"
        >
          Go
        </button>
      </div>
    </div>
  </div>
  
  );
};

export default Pagination;
