import React, { useState, useEffect } from 'react';

const BackToTopGlobal = () => {
  const [visible, setVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const checkScrollTop = () => {
        if (!visible && window.scrollY > 300) {
          setVisible(true);
        } else if (visible && window.scrollY <= 300) {
          setVisible(false);
        }
      };
    
    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [visible]);

  return (
    <button
      onClick={scrollToTop}
      className={`fixed bottom-4 right-4 p-3 bg-retropink text-white rounded-full shadow-lg transition-opacity z-20 ${
        visible ? 'opacity-100' : 'opacity-0'
      }`}
      style={{ transition: 'opacity 0.3s' }}
    >
      ↑
    </button>
  );
};

export default BackToTopGlobal;
